import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import Graphic from "@arcgis/core/Graphic.js";
import Point from "@arcgis/core/geometry/Point.js";
import SpatialReference from "@arcgis/core/geometry/SpatialReference.js";

// import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import { isProxy, toRaw } from 'vue';

const state = {
    activeTab: "tab1",
    testString: "NULL TEST",
    appContainer: null,
    preserveBldg: false,
    searchSources: [],
    searchResults: [],
    selectedLayers: [],
    selectedSources: [],
    selectedSourcesName: [],
    selectedFeature: null,
    searchWidget: {},
    highlightInteriorBuilding: [],
    buildingFeatures: [],    
    interiorLyrsMapping: [
        {FLID:"1", urlRef: "16" },
        {FLID:"2", urlRef: "17" },
        {FLID:"3", urlRef: "18" },
        {FLID:"4", urlRef: "19" },
        {FLID:"5", urlRef: "20" },
        {FLID:"6", urlRef: "21" },
        {FLID:"7", urlRef: "22" },
        {FLID:"8", urlRef: "23" },
        {FLID:"9", urlRef: "24" },
        {FLID:"10", urlRef: "25" },
        {FLID:"11", urlRef: "26" },
        {FLID:"12", urlRef: "27" },
        {FLID:"B", urlRef: "15" },
    ],

    bldgsList: [],
    bldgsQry: [],
    bldgAddress: null,
    selectEquip: undefined,
    selectClassification: undefined,
    selectCapacity: undefined,
    selectIntBldg: undefined,
    selectIntRm: undefined,
    selectIntRmOID: undefined,
    roomNumbers: [],
    routerRoom: undefined,
    roomMessage: undefined,
    highlightSource: 'search',
    roomFloorsString: undefined,    
    btnToggleTable: false,
    leftDrawer: true,

    currImgIdx: 0,

    classification: [],
    equipList: [],
    classificationList: ['Classroom','Seminar','Lecture Hall','Collaborative Classroom','Live Stream Studio'],
    capacityList:[{id:1, range:'1-20'},{id:2, range:'21-30'},{id:3, range:'31-49'},{id:4, range:'50-60'},{id:5, range:'61-75'},
    {id:6, range:'76-99'},{id:7, range:'100-199'},{id:8, range:'200-299'},{id:9, range:'300-575'}],
    tableContainer: null,
    displayRightDr: false,

    featureTable: null,
    mobileTopTab: 'table'
};

const getters = {
    getSearchSources: (state) => state.searchSources,
    getSearchResults: (state) => state.searchResults,
    getSelectedLayers: (state) => state.selectedLayers,
    getSelectedSources: (state) => state.selectedSources,
    getselectedSourcesName: (state) => state.selectedSourcesName,
    getHighlightInteriorBuilding: (state) => state.highlightInteriorBuilding,
    getsearchWidget: (state) => state.searchWidget,
    getbuildingFeatures: (state) => state.buildingFeatures,
    getAppContainer: (state) => state.appContainer,
    getSelectIntBldg: (state) => state.selectIntBldg,
    getSelectIntRm: (state) => state.selectIntRm,
    getSelectIntRmOID: (state) => state.selectIntRmOID,
    getRoomNumbers: (state) => state.roomNumbers,
    getRouterRoom: (state) => state.routerRoom,
    getRoomMessage: (state) => state.roomMessage,
    getHighlightSource: (state) => state.highlightSource,
    getroomFloorsString: (state) => state.roomFloorsString,    
    getClassification: (state) => state.classification,
    getBtnToggleTable: (state) => state.btnToggleTable,
    getTableCont: (state) => state.tableContainer,
    getDispRightDr: (state) => state.displayRightDr,
    getLeftDr: (state) => state.leftDrawer,

    getBldgsList: (state) => state.bldgsList,
    getFeatureTable: (state) => state.featureTable,
    getEquipList: (state) => state.equipList,

    getClassificationList: (state) => state.classificationList,
    getCapacityList: (state) => state.capacityList,

    getSelectedClassification: (state) => state.selectClassification,
    getSelectedCapacity: (state) => state.selectCapacity,
    
    getSelectedEquip: (state) => state.selectEquip,
    getCurrImgIdx: (state) => state.currImgIdx,

    getPreserveBldg: (state) => state.preserveBldg,

    getActiveTab:(state) => state.activeTab,
    getMobTopTab: (state) => state.mobileTopTab,

    getBldgAddress: (state) => state.bldgAddress
};

const mutations = {
    SET_SRCH_SRC: (state, payload) => {
        state.searchSources = payload;
    },

    SET_SRCH_RES: (state, payload) => {
        state.searchResults = payload;
    },

    SET_SLTD_LYRS: (state, payload) => {
        state.selectedLayers = payload;
    },

    SET_APP_CONTAINTER: (state, payload) => {
        state.appContainer = payload;
    },

    SET_SLTD_SRCS: (state, payload) => {
        state.selectedSources = payload;
    },

    SET_SLETD_SRCSNAMES: (state, payload) => {
        state.selectedSourcesName = payload;
    },

    SET_HLGHT_INT: (state, payload) => {
        state.highlightInteriorBuilding = payload;
    },

    SET_SRCH_WDGT: (state, payload) => {
        state.searchWidget = payload;
    },

    SET_BLDG_FEATS: (state, payload) => {
        state.buildingFeatures = payload;
    },

    // 
    SET_ACTIVE_TAB: (state, tabName) => {        
        state.activeTab = tabName;
        // console.log("state.activeTab::", state.activeTab)
    },

    SET_INT_BLDG: (state, payload) => {        
        state.selectIntBldg = payload;
        // console.log("SET INT Building:: ", payload)
    },

    SET_INT_RM: (state, tabName) => {
        state.selectIntRm = tabName;
    },

    SET_INT_RM_OID: (state, tabName) => {
        state.selectIntRmOID = tabName;
    },

    SET_RM_NUM: (state, tabName) => {
        state.roomNumbers = tabName;
        // console.log("Set room number:: ", state.roomNumbers)
    },

    SET_RM_MSG: (state, tabName) => {
        state.roomMessage = tabName;
    },

    SET_HLGTSRC: (state, tabName) => {
        state.highlightSource = tabName;
    },

    SET_RMFLR_STR: (state, tabName) => {
        state.roomFloorsString = tabName;
    },

    SET_CLASSIFICATION: (state, tabName) => {
        state.classification = tabName;
    },

    SET_CLASSIFICATION_PUSH: (state, payload) => {
        state.classification['equiplist'] = payload;
    },

    SET_CLASSIFICATION_PUSH_IMGS: (state, payload) => {
        state.classification['imgs'] = payload;
    },


    SET_BTN_TOGGLE_TBL: (state, payload) => {
        // console.log("Show drawer:", payload)
        state.btnToggleTable = payload;
    },

    SET_TABLE_CONT: (state, payload) => {
        state.tableContainer = payload;
    },

    SET_DISP_RIGHT_DR: (state, payload) => {
        state.displayRightDr = payload;
        // console.log(state.displayRightDr)
    },

    SET_BLDGS_LIST: (state, payload) => {
        state.bldgsList = payload
    },

    SET_FEATURE_TBL: (state, payload) => {
        state.featureTable = payload
    },

    SET_FEATURE_TBL_CONTAINER: (state, payload) => {
        const FTble = toRaw(state.featureTable)
        FTble.container = payload

        // console.log("SET CONTAINGER",state.featureTable)
        // FTble.refresh()
    },

    SET_EQUIP_LST: (state, payload) => {
        state.equipList = payload
    },

    SET_SELECTED_EQUIP: (state, payload) => {
        state.selectEquip = payload
    },

    SET_CURR_IMG_IDX: (state, payload) => {
        state.currImgIdx = payload
    },

    SET_LEFT_DR: (state, payload) => {
        state.leftDrawer = payload
    },

    SET_SEL_FEAT: (state, payload) => {
        state.selectedFeature = payload
    },

    SET_MOB_TOP_TAB: (state, payload) => {
        state.mobileTopTab = payload
    },

    SET_PRESERVE_BLDG: (state, payload) => {
        state.preserveBldg = payload
    },

    SET_SELECT_CLASSIFICATION: (state, payload) => {
        console.log("SET_SELECT_CLASSIFICATION::",payload)
        state.selectClassification = payload
    },

    SET_SELECT_CAPACITY: (state, payload) => {
        state.selectCapacity = payload
    },

    SET_BLDGS_QRY: (state, payload) => {
        state.bldgsQry = payload
    },

    SET_BLDG_ADDRESS: (state, payload) => {
        state.bldgAddress = payload
        // console.log("state.bldgAddress::",state.bldgAddress)
    },

};

const actions = {
    // SET_ACTIVE_TAB: ({ commit }, tabName) => {
    //     commit('setActiveTab', tabName);
    // },

    populateRoomEquipment: ({ commit }, payload) => {
        
        let equipList = []
        const counts = {}
        const featureLayerClassEquip = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/0",
        });

        let queryEquip = featureLayerClassEquip.createQuery();
        queryEquip.where = "Join_Key = '" + payload + "'";
        // query.outFields = "*"
        featureLayerClassEquip.queryFeatures(queryEquip).then(function (response) {
            // console.log("Equipment response.features:: ", response.features)
            if (response.features.length > 0) {
                response.features.forEach(r => {
                    // console.log(r.attributes.DisplayName)
                    let dName = r.attributes.DisplayName
                    switch(dName){
                        case "Video Projector":
                            dName = "Video Projector(s)"
                            break;
                        case "Video Projectors":
                            dName = "Video Projector(s)"
                            break;
                        case "Flat Panel Display":
                            dName = dName+'(s)'
                            break;
                        case "Wireless Handheld Microphone":
                            dName = dName+'(s)'
                            break;
                        case "Wireless Lavalier Microphone":
                            dName = dName+'(s)'
                            break;
                        case "Sound System":
                            dName = dName+'(s)'
                            break;
                        defaul:
                            dName = dName                                        
                        }
                        // equipList.push(r.attributes.DisplayName)
                    let currCount = equipList.filter(x => x === dName).length
                    // console.log("Current Count for ", dName," :: ", currCount,"  Display count:", r.attributes.DisplayCount)   
                   if (currCount == 0) {
                       equipList.push(dName)
                   } else if (r.attributes.DisplayCount == "true") {
                           equipList.push(dName)
                       }
                })

                // Total categoriezed equpments
                if (equipList.length > 0) {
                    equipList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                    // console.log("Counts::", counts)
                    commit('SET_CLASSIFICATION_PUSH', counts)
                }
            }
        })

    },

    populateRoomImages: ({ commit }, payload) => {
        // console.log("populateRoomImages:: ",payload[0] + "+" + payload[1])
        var firstLetter = payload[1].charAt(0)
        //console.log("1.First Character:" + firstLetter);
        var letters = /^[A-Za-z]+$/;
        //if first charcter is Not a letter like S104, append 0
        if(firstLetter.match(letters) == null)
        {            
            payload[1] = "0" + + payload[1]
        }
        let imgs = []
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        var raw = JSON.stringify({ "roomKey": payload[0] + "+" + payload[1] });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch("https://api.pdc.arizona.edu/photos/room", requestOptions)
            .then(response => response.text())
            .then((result) => {
                // JSON.parse converts return array string to array 
                // Doc -> https://stackoverflow.com/questions/74759623/react-getting-a-string-instead-of-array-of-objects-after-using-fetch                
                JSON.parse(result).forEach(r => {
                    if(r['tags'] !== null){
                        if(r['tags'][0] == "Layout" ) {
                            imgs.unshift(r['url']) // ADD at the first position
                        } else { 
                            imgs.push(r['url']) 
                        }
                    } else {
                        imgs.push(r['url'])  
                    }
                })                
                commit('SET_CURR_IMG_IDX',0)
                commit('SET_CLASSIFICATION_PUSH_IMGS', imgs)
            })
            .catch(error => console.log('error', error));
    },


    refreshFeatureLayer ({ state, commit, rootState, dispatch }, bldgName) {
        // var allLayers = map.allLayers.items;

        

        // let rooms = []
        let defExp = { }
        let definitionExpression=  "SpaceNumText = '" + bldgName + "'";
        if (Array.isArray(bldgName)){
            for(let i = 0; i<bldgName.length; i++){
                if(i == 0) defExp =  "Join_Key = '" + bldgName[i] + "'";
                else defExp += " OR Join_Key = '" + bldgName[i] + "'";
            }
            // console.log("Def Exp:: ", defExp);
            definitionExpression = defExp
         } else if (!bldgName) { //display all buildings
            definitionExpression = "1=1"
         }

        //  console.log(">>> Refresh Feature layer...bldgName::",bldgName)

        const view = toRaw(rootState.mapFuncs.View)
        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        var allLayers = map.allLayers.items;
        const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        // console.log("Tble:: ", bldgName)
        const featureLayerClassEquip = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/1",
            definitionExpression: definitionExpression
        });

        // console.log("View is classroomTable:: ", allLayers, toRaw(state.featureTable))


        let featTbl = toRaw(state.featureTable)        
        featTbl.layer = featureLayerClassEquip;

        setTimeout(() => {
            if(featTbl.grid.columns.items.length > 0) {
                dispatch('buildingSelectHeader')   
                featTbl.grid.columns.items[1].width = 100 // Adjust Rooms Width
                featTbl.grid.columns.items[2].width = 200 // Adjust Rooms Width
                featTbl.grid.columns.items[3].width = 150 // Adjust Rooms Width 
            }
        }, 400);
              
    },
    
    loadRooms: ({ state,commit, rootState }, payload) => { 
        return new Promise((resolve, reject) => {
            let rooms = []
            let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
            var allLayers = map.allLayers.items;
            const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
     
            // console.log("Load Rooms...")
            // const featureLayerClassEquip = payload
            // const featureLayerClassEquip = new FeatureLayer({
            //     url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/1",
            //     definitionExpression: definitionExpression
            // });
            // let query = payload.createQuery();
           let featTbl = toRaw(state.featureTable)
           const fLyer = featTbl.layer
            let query = fLyer.createQuery();
            fLyer.queryFeatures(query).then(function (response) {
                if (response.features.length > 0) {
                    roomGLayer.removeAll();
                    let room = {}
                    let feature = response.features
                    // console.log("attributes:: ", response.features)
                    feature.forEach(rm => {
                        room.OBJECTID = rm.attributes.OBJECTID
                        room.ID = rm.attributes.RoomID
                        room.FL = rm.attributes.FLID
                        // console.log("room:: ", rm)
                        rooms.push({ ID: room.ID, FL: rm.attributes.FLID, RoomKey: rm.attributes.Join_Key, OBJECTID: rm.attributes.OBJECTID })
                        
                    })
                    // console.log("#### Refresh Room Layer rooms:: ", rooms)
                    commit('SET_RM_NUM', rooms)
                    resolve(rooms)
                    
                    // dispatch('highlightBuilding', bldgName) // highlight and zoom to building selected
                    // dispatch('searchHighlightInteriorRooms', [bldgName, null, null])
                    
                }
            })
            .catch(error => {
                commit('setError', error);
                reject(error);
              })
              .finally(() => {
                // commit('setLoading', false);
                // console.log("Finally::", e``)
              })
        })
    },

    async highlightBuilding ({ rootState,commit }, payload) {
        
        let view = toRaw(rootState.mapFuncs.View)
        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        let zoomLevel = 19
        
        const isMobile = rootState.mapFuncs.isMobile
        if(isMobile) zoomLevel = 18
        
        var allLayers = map.allLayers.items;
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        const buildingGLayer = allLayers.find(l => (l.id == "buildingGLayer"))
        const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        let _this = this;
        // console.log("...Highlight Buildings...",bldgsFL)
        // Select Building on the map
        let query = bldgsFL.createQuery();
        // query.where = "OBJECTID = "+features[0]
        query.where = "Buildings.SpaceNumLetter = '" + payload + "'";
        
        bldgsFL.queryFeatures(query).then(function (response) {
            // console.log("query::", query,  " --- feature returned::",response.features.length)
            if (response.features.length > 0) {
                // console.log("response::", response.features);
                // building = response.features
                
                let address =  response.features[0].attributes["Buildings.Address"]
                // state.bldgAddress = address
                // commit('SET_BLDG_ADDRESS',address)
                // console.log("Target:: ",response.features[0], "-- ", state.bldgAddress)
                let geometry = response.features[0].geometry
 
                setTimeout(() => {
                    var opts = {
                        duration: 1000, // Duration of animation will be 5 seconds
                    };
                    view.goTo(
                        {
                            // target: response.features[0].geometry,
                            target: geometry,
                            zoom: zoomLevel
                        },
                        opts
                    );    
                    commit('SET_BLDG_ADDRESS',address)
                }, 500);

                // Add Buildings on the map
                let graphicA = new Graphic({
                    // graphic with line geometry
                    geometry: response.features[0].geometry, // set geometry here
                    symbol: {
                        type: "simple-fill",                        
                        outline: { width: 3, color: [171, 5, 32] },
                        color: [255, 255, 255, 0.15],
                    }, // set symbol here
                });

                buildingGLayer.removeAll();
                roomGLayer.removeAll();
                buildingGLayer.graphics.add(graphicA);
            //    return response.features[0]
            }
        });
    },

    searchHighlightInteriorRooms: ({ state, dispatch, rootState }, payload) => {
        // console.log("searchHighlightInteriorRooms::", payload)
        let spaceNumText = payload[0]
        let lyrID = 16
        let FL = '1'
        if(payload[2]){
            let lyrItem = state.interiorLyrsMapping.find(lyr => lyr.FLID == payload[2])
            lyrID = toRaw(lyrItem)
            // console.log("LayerID::", lyrID," :: ", lyrID.urlRef);
            lyrID =  lyrID.urlRef // Add this layer id in the URL to get the right floor
            FL = payload[2]
        }

        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        var allLayers = map.allLayers.items;
        const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        const roomLabels = allLayers.find(l => (l.id == "roomLabels"))
        roomGLayer.removeAll()
        roomLabels.removeAll()
        var textSymbol = {
            type: "text",  // autocasts as new TextSymbol()
            color: "black",
            text: '',
            yoffset: -3,
            font: {  // autocasts as new Font()
                size: 8,
            }
        };

        // Select Room on the map
        let symbolW = {
            type: "simple-fill",
            outline: { color: [119, 119, 119, 3] },
            color: [255, 255, 255, 0.8],
        };

        const featureLayerRms = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/Interior/MapServer/"+lyrID,
        });
        // console.log("https://services.maps.arizona.edu/pdc/rest/services/Interior/MapServer/"+lyrID)
        // let g = 51
        // let b = 51
        let queryRm = featureLayerRms.createQuery();        
        queryRm.where = "ROOMEXT.BldgAlpha = '" + spaceNumText + "' AND ROOMEXT.fl_id ='"+ FL +"'";
        // query.outFields = "*"
        featureLayerRms.queryFeatures(queryRm).then(function (response) {
            if (response.features.length > 0) {
                // console.log("response.features::",response.features)
                // Find the highlighted feature below and add it on the map later
                let rms = [];
                const colorsCbfRainbowRGB = [
                    [120,28,129],
                    [64,67,153],
                    [72,139,194],
                    [107,178,140],
                    [159,190,87],
                    [210,179,63],
                    [231,126,49],
                    [217,33,32]
                ]
                let i = 0
                response.features.forEach((g) => {

                    let rmGraphic;

                    if (payload[1] == null) { //means there are multi rooms
                        let roomList = toRaw(state.roomNumbers)
                        
                        // let currRoom = roomList.find(rm => (rm.RoomKey == g.attributes["Level_1.Join_Key"])  )
                        roomList.find(rm => {
                                if(rm.RoomKey == g.attributes["Level_"+FL+".Join_Key"]){
                                console.log(rm.RoomKey, "==", g.attributes["Level_"+FL+".Join_Key"])
                                console.log("Match Found...");

                                let symbol = {
                                    type: "simple-fill",
                                    outline: { color: [119, 119, 119, 3] },
                                    // color: [255, 51, 51, 0.5],
                                    color: colorsCbfRainbowRGB[i]
                                };
                                i = i+1;
                                rmGraphic = new Graphic({
                                    // graphic with line geometry
                                    geometry: g.geometry,
                                    symbol: symbol,
                                });
                            }

                            rms.push(rmGraphic);
                            // console.log(rm ," -- ",g.attributes["Level_1.Join_Key"] )
                        })
                    }

                    if (g.attributes["Level_"+FL+".Join_Key"] == payload[1]) {
                        // console.log(g.attributes["Level_1.Join_Key"]+" = "+ _this.roomkey);
                        // console.log("Match Found...>>>");
                        let symbol = {
                            type: "simple-fill",
                            outline: { color: [119, 119, 119, 3] },
                            color: [255, 51, 51, 0.8],
                        };
                        rmGraphic = new Graphic({
                            // graphic with line geometry
                            geometry: g.geometry,
                            symbol: symbol,
                        });
                    } else {                        
                        rmGraphic = new Graphic({
                            // graphic with line geometry
                            geometry: g.geometry,
                            symbol: symbolW,
                        });
                    }

                    // rms.push(rmGraphic);
                    roomGLayer.graphics.add(rmGraphic)

                    
                    var ext = g['geometry'].extent
                    // console.log(">>> ext:: ", ext)

                    if (ext.width > 3.8 ){ // && e.attributes["ROOMEXT.RoomUse"] !== 'Circulation') {
                        var gCenter = ext.center;                        
                        let roomNum = g.attributes["ROOMEXT.RM_ID"]
                        if (roomNum[0] == "0") {
                            roomNum = roomNum.substr(1);
                        }
                        textSymbol.text = roomNum
                        let txtGraphic = new Graphic(gCenter, textSymbol)
                        // roomLabels.add(txtGraphic)
                        rms.push(txtGraphic);
                    }
                });
                // roomGLayer.graphics.addMany(rms);
                roomLabels.graphics.addMany(rms);
               
            }
        });
    },

    clearGLayers : ({ rootState}) => {
        let map = toRaw(rootState.mapFuncs.Map)
        var allLayers = map.allLayers.items;
        // const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        const buildingGLayer = allLayers.find(l => (l.id == "buildingGLayer")) // Building Boundary layer
        const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        const roomLabels = allLayers.find(l => (l.id == "roomLabels"))
        roomGLayer.removeAll();
        buildingGLayer.removeAll();
        roomLabels.removeAll();
    },

    hideBuildingsLyr: ({ rootState }) => { 
        // console.log("--> All Layers Hide Buildgs Layer");
        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        var allLayers = map.allLayers.items;
        // console.log("::: All Layers:: ", allLayers)
        
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        if (bldgsFL)  bldgsFL.visible = false
        // console.log("Remove BLDGFL:: ", bldgsFL)
        // map.remove(bldgsFL)
    },

    displayBuildingsLyr: ({ rootState }) => {  
        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        var allLayers = map.allLayers.items;        
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        // console.log("Display buildings..::",bldgsFL, state.bldgsQry)
        bldgsFL.definitionExpression = "Buildings.SpaceNumLetter IN ("+state.bldgsQry+")"
        bldgsFL.visible = true
    },

    removeLayers: ({ state, commit, dispatch, rootState }, bldgName) => {  
        let view = toRaw(rootState.mapFuncs.View)
        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        var allLayers = map.allLayers.items;
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        const buildingGLayer = allLayers.find(l => (l.id == "buildingGLayer"))
        const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        buildingGLayer.removeAll();
        roomGLayer.removeAll();
        // buildingGLayer.graphics.add(graphicA);
    },

    populateClassification: ({ commit },payload) => {         
        var classroomInfo = {};        
        const featureLayerClassEquip = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/1",
        });
        let query = featureLayerClassEquip.createQuery();
        query.where = "Join_Key = '"+payload[0]+"'";  // query for non-null values
        // query.orderByFields = ["DisplayName"];    // sort it so we won't have to later
        query.returnGeometry = false;          // turn geometry off, required to be false when using returnDistinctValues
        query.returnDistinctValues = true;
        // query.outFields = ["DisplayName"];
        query.outFields = "*"
        featureLayerClassEquip.queryFeatures(query).then(function (response) {
            // console.log("Classification List::>> ", response.features)
            if (response.features.length > 0) {
                let attribs = response.features[0].attributes;
                // console.log("Bldg Info setter:: total:",response.features.length," attribt:", attribs)

                classroomInfo["Building"] = attribs.Building;
                classroomInfo["RoomID"] = payload[1];
                classroomInfo["FLID"] = attribs.FLID;
                classroomInfo["Classification"] = attribs.Classification;
                classroomInfo["Configuration"] =attribs.Configuration;
                classroomInfo["SpaceType"] = attribs.SpaceType;
                classroomInfo["Capacity"] = attribs.Capacity;
                classroomInfo["Stage"] = attribs.Stage;
                classroomInfo["WritingSurface"] = attribs.WritingSurface;
                classroomInfo["PanaptoInstalled"] = attribs.PanaptoInstalled;
                classroomInfo["PanaptoElmo"] = attribs.PanaptoInstalled;
                classroomInfo["PanaptoRoomDesktopCamera"] = attribs.PanaptoRoomDesktopCamera;
                classroomInfo["StudentWheelchairAccess"] = attribs.StudentWheelchairAccess;
                classroomInfo["TeachingWheelchairAccess"] = attribs.TeachingWheelchairAccess;
            }
            // console.log("Classification List::>> ", classroomInfo)
            commit('SET_CLASSIFICATION', classroomInfo);
        })
    },

    populateDistEquipment: ({ commit }) => { 
        let equipList = []
        // const counts = {}
        const featureLayerClassEquip = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/0",
        });
        let query = featureLayerClassEquip.createQuery();
        query.where = "1 = 1";  // query for non-null values
        query.orderByFields = ["DisplayName"];    // sort it so we won't have to later
        query.returnGeometry = false;          // turn geometry off, required to be false when using returnDistinctValues
        query.returnDistinctValues = true;
        query.outFields = ["DisplayName"];
        // query.outFields = "*"
        featureLayerClassEquip.queryFeatures(query).then(function (response) {
            // console.log("Equip List:: ", response.features)
            if (response.features.length > 0) {
                response.features.forEach(r => {
                    // console.log(r.attributes.DisplayName)
                    equipList.push(r.attributes.DisplayName)
                })
                // console.log("Equip List:: ", equipList)
                commit('SET_EQUIP_LST', equipList)
                /** 
                // Total categoriezed equpments
                if (equipList.length > 0) {
                    equipList.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
                    // console.log(counts)
                    commit('SET_CLASSIFICATION_PUSH', counts)

                }
                */
            }
        })
    },

    zoomtoMainCampus: ({ rootState }, DisplayName) => { 
        const view = toRaw(rootState.mapFuncs.View)
        const point = new Point({
            latitude: 32.231866,
            longitude: -110.953441
        });

        view.goTo({
            target: point,
            zoom: 15
        }, {
            duration: 1000  // Duration of animation will be 5 seconds
        });
    },

    populateEquipments: ({ dispatch,rootState }, DisplayName) => { 
        // dispatch('clearGLayers')
        const view = toRaw(rootState.mapFuncs.View)
        const point = new Point({
            latitude: 32.231866,
            longitude: -110.953441
        });
        if(DisplayName){
            view.goTo({
                target: point,
                zoom: 15
            }, {
                duration: 1000  // Duration of animation will be 5 seconds
            });
        }

        
        let equipList = []
        // const counts = {}
        const featureLayerClassEquip = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/0",
        });
        let query = featureLayerClassEquip.createQuery();
        if(DisplayName) query.where = "DisplayName = '"+DisplayName+"'";  // query for non-null values
        else query.where = "1 = 1"
        query.orderByFields = ["SpaceNumText"];    // sort it so we won't have to later
        query.returnGeometry = false;          // turn geometry off, required to be false when using returnDistinctValues
        query.returnDistinctValues = true;
        // query.outFields = ["DisplayName"];
        query.outFields = "*"
        let buildings = []
        let classRooms = []
        let attribs = []
        featureLayerClassEquip.queryFeatures(query).then(function (response) { 
            // console.log("Buildings Rooms:: ",response.features)
            response.features.forEach(f=>{
                attribs.push(f.attributes)
            })
            // buildings = attribs.filter((i) => i.SpaceNumText !== null) 
            // console.log("Equipment attribs:: ",attribs)
            attribs.forEach(a=>{
                // console.log("a::", a)
                buildings.push(a["SpaceNumText"])
                while(a["RoomID"].charAt(0) === '0')
                {
                    a["RoomID"] = a["RoomID"].substring(1);
                }
                // classRooms.push(a["RoomID"])    
                classRooms.push(a["Join_Key"])    
            })
            buildings = [...new Set(buildings)]
            classRooms = [...new Set(classRooms)]
            // console.log("buildings:: ",buildings, " -- classRooms:: ",classRooms)

            dispatch('refreshFeatureLayer',classRooms)
            dispatch('refreshBuildings',buildings)
        })
    },

    populateClassificationOnMap: ({ dispatch,rootState }, DisplayName) => { 
        // dispatch('clearGLayers')
        const view = toRaw(rootState.mapFuncs.View)

        // if(!DisplayName){
            const point = new Point({
            latitude: 32.231866,
            longitude: -110.953441
        });

        view.goTo({
            target: point,
            zoom: 15
        }, {
            duration: 1000  // Duration of animation will be 5 seconds
        });
        // }
        
        

        let equipList = []
        // const counts = {}
        const featureLayerClassEquip = new FeatureLayer({
            url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/1",
        });
        let query = featureLayerClassEquip.createQuery();
        if(DisplayName) 
            {
                query.where = "Classification = '"+DisplayName+"'";  // query for non-null values
            }
        else 
            {
                query.where = "1 = 1"
                // console.log("Where query:: ", query.where)
            }
        query.orderByFields = ["SpaceNumText"];    // sort it so we won't have to later
        query.returnGeometry = false;          // turn geometry off, required to be false when using returnDistinctValues
        query.returnDistinctValues = true;
        // query.outFields = ["DisplayName"];
        query.outFields = "*"
        let buildings = []
        let classRooms = []
        let attribs = []
        featureLayerClassEquip.queryFeatures(query).then(function (response) { 
            // console.log("Buildings Rooms:: ",response.features)
            response.features.forEach(f=>{
                attribs.push(f.attributes)
            })
            // buildings = attribs.filter((i) => i.SpaceNumText !== null) 
            // console.log("Equipment attribs:: ",attribs)
            attribs.forEach(a=>{
                // console.log("a::", a)
                buildings.push(a["SpaceNumText"])
                while(a["RoomID"].charAt(0) === '0')
                {
                    a["RoomID"] = a["RoomID"].substring(1);
                }
                // classRooms.push(a["RoomID"])    
                classRooms.push(a["Join_Key"])    
            })
            buildings = [...new Set(buildings)]
            classRooms = [...new Set(classRooms)]
            // console.log("buildings:: ",buildings, " -- classRooms:: ",classRooms)

            dispatch('refreshFeatureLayer',classRooms)
            dispatch('refreshBuildings',buildings)
        })
    },

    populateCapacityOnMap: ({ dispatch,rootState }, payload) => { 
                let queryWhere = ""
                switch(payload){
                    case 1:
                        queryWhere = "Capacity >=1 and Capacity <=20" 
                    break;
                    case 2:
                        queryWhere = "Capacity >=21 and Capacity <=30" 
                    break;
                    case 3:
                        queryWhere = "Capacity >=31 and Capacity <=49" 
                    break;
                    case 4:
                        queryWhere = "Capacity >=50 and Capacity <=60" 
                    break;                    
                    case 5:
                        queryWhere = "Capacity >=61 and Capacity <=75" 
                    break;
                    case 6:
                        queryWhere = "Capacity >=76 and Capacity <=99" 
                    break;
                    case 7:
                        queryWhere = "Capacity >=100 and Capacity <=199" 
                    break;
                    case 8:
                        queryWhere = "Capacity >=200 and Capacity <=299" 
                    break;
                    case 9:
                        queryWhere = "Capacity >=300 and Capacity <=575" 
                    break;
                    
                }
                // dispatch('clearGLayers')
                const view = toRaw(rootState.mapFuncs.View)
                const point = new Point({
                    latitude: 32.231866,
                    longitude: -110.953441
                });

                view.goTo({
                    target: point,
                    zoom: 15
                }, {
                    duration: 1000  // Duration of animation will be 5 seconds
                });
                let equipList = []
                // const counts = {}
                const featureLayerClassEquip = new FeatureLayer({
                    url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/1",
                });
                let query = featureLayerClassEquip.createQuery();
                if(payload) query.where = queryWhere
                else query.where = "1 = 1"
                query.orderByFields = ["SpaceNumText"];    // sort it so we won't have to later
                query.returnGeometry = false;          // turn geometry off, required to be false when using returnDistinctValues
                query.returnDistinctValues = true;
                query.outFields = "*"
                let buildings = []
                let classRooms = []
                let attribs = []
                featureLayerClassEquip.queryFeatures(query).then(function (response) { 
                    // console.log("Buildings Rooms:: ",response.features)
                    response.features.forEach(f=>{
                        attribs.push(f.attributes)
                    })
                    // buildings = attribs.filter((i) => i.SpaceNumText !== null) 
                    // console.log("Equipment attribs:: ",attribs)
                    attribs.forEach(a=>{
                        // console.log("a::", a)
                        buildings.push(a["SpaceNumText"])
                        while(a["RoomID"].charAt(0) === '0')
                        {
                            a["RoomID"] = a["RoomID"].substring(1);
                        }
                        // classRooms.push(a["RoomID"])    
                        classRooms.push(a["Join_Key"])    
                    })
                    buildings = [...new Set(buildings)]
                    classRooms = [...new Set(classRooms)]
                    // console.log("buildings:: ",buildings, " -- classRooms:: ",classRooms)

                    dispatch('refreshFeatureLayer',classRooms)
                    dispatch('refreshBuildings',buildings)
                })
            },

    refreshBuildings: ({ dispatch,rootState }, buildings) => { 
        dispatch('removeLayers')
        // console.log("refresh buildings..",buildings)
        // const view = toRaw(rootState.mapFuncs.View)
        let map = toRaw(rootState.mapFuncs.Map)
        var allLayers = map.allLayers.items;
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        bldgsFL.visible = true
        // this.featureLayerBldg
        let defExp = buildings
        if (Array.isArray(buildings)){
            for(let i = 0; i<buildings.length; i++){
                if(i == 0) defExp =  "Buildings.SpaceNumLetter = '" + buildings[i] + "'";
                else defExp += " OR Buildings.SpaceNumLetter  = '" + buildings[i] + "'";
            }
            // console.log("Def Exp:: ", defExp);        
         }
         bldgsFL.definitionExpression = defExp        
         bldgsFL.when((e)=>{ 
            // console.log("Feature Layer Buildings Update::",e)
          })
    },

    selectRoomFeatTable: ({ state}) => { 
        // console.log(">>> state.selectIntRmOID:: ",state.selectIntRmOID)
        if(state.selectIntRmOID){
            let featTbl = toRaw(state.featureTable)
            // console.log(">> Featur Tble: ", featTbl,"--room.OBJECTID:: -",state.selectIntRmOID)
            featTbl.highlightIds.add(state.selectIntRmOID)
        }        
    },

    async processSelectBuilding({state, commit, dispatch,rootState},payload) { 
        //Move all these to a function
        // dispatch('clearGLayers')
        let map = toRaw(rootState.mapFuncs.Map)
        var allLayers = map.allLayers.items;
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        // const buildingGLayer = allLayers.find(l => (l.id == "buildingGLayer")) // Building Boundary layer
        // const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        // console.log("Process Building::", payload, bldgsFL)
        const roomLabels = allLayers.find(l => (l.id == "roomLabels"))
        roomLabels.removeAll()
        commit('SET_INT_BLDG',payload);
        commit('SET_INT_RM',null);
        commit('SET_INT_RM_OID',null);
        
        commit('SET_DISP_RIGHT_DR',false) // Close the right menu
        payload == null?dispatch('displayBuildingsLyr'):dispatch('hideBuildingsLyr')
        if(state.activeTab == 'tab1')    
        {
            dispatch('refreshFeatureLayer',payload); //this function add rooms on the map
            
        }    
        else {
            dispatch('refreshFeatureLayer',null); //this function add rooms on the map            
        }
        
        // console.log("Preserve Building:: ",state.preserveBldg)
        if(state.preserveBldg && bldgsFL)
        {
            return dispatch('highlightBuilding',state.selectIntBldg).then((f) => {
                //Building highlighted now load rooms
                if(state.selectIntBldg) {
                    // console.log("Inside the load rooms")
                    dispatch('loadRooms')
                }            
            }) // highlight and zoom to building selected
        }        
        // this.searchHighlightInteriorRooms([this.getSelectIntBldg, null, null])
    },

    clearAllBuildings: ({state, commit, dispatch}) => { 
        //Move all these to a function
        // console.log("Clear buildings in store...")
        // dispatch('clearGLayers')
        commit('SET_INT_BLDG',null);
        commit('SET_INT_RM',null);
        commit('SET_INT_RM_OID',null);
        dispatch('refreshFeatureLayer',null)
        
        dispatch('clearGLayers');
        dispatch('displayBuildingsLyr');

        dispatch('zoomtoMainCampus');        
        state.roomNumbers = []
    },

    clearBuildings: ({state, commit, dispatch}) => { 
        //Move all these to a function
        // console.log("Clear buildings in store...")
        commit('SET_INT_BLDG',null);
        commit('SET_INT_RM',null);
        commit('SET_INT_RM_OID',null);
        // dispatch('refreshFeatureLayer',null)
        // dispatch('zoomtoMainCampus');        
        // dispatch('clearGLayers');
        // dispatch('displayBuildingsLyr');
        state.roomNumbers = []
    },

    removeRoomGraphics: ({rootState, commit}) => {  
        commit('SET_INT_RM',null);
        commit('SET_INT_RM_OID',null);
        commit('SET_DISP_RIGHT_DR',false )
        // dispatch('drawerVisiblity');
        let map = toRaw(rootState.mapFuncs.Map)  //convert proxies to raw https://chrysanthos.xyz/article/how-to-get-the-data-of-a-proxy-object-in-vue-js-3/
        var allLayers = map.allLayers.items;
        const roomGLayer = allLayers.find(l => (l.id == "roomGLayer"))
        const roomLabels = allLayers.find(l => (l.id == "roomLabels"))
        roomGLayer.removeAll()
        roomLabels.removeAll()
    },

    selectEquipAction: ({dispatch, commit}, value) => {          
        commit('SET_SELECTED_EQUIP', value)
        dispatch('populateEquipments',value)
        commit('SET_DISP_RIGHT_DR',false)
    },

    populateClassificationAction: ({dispatch, commit}, value) => {          
        commit('SET_SELECT_CLASSIFICATION', value)
        dispatch('populateClassificationOnMap',value)
        commit('SET_DISP_RIGHT_DR',false)
    },

    populateCapacityAction: ({dispatch, commit}, value) => {          
        commit('SET_SELECT_CAPACITY', value)
        dispatch('populateCapacityOnMap',value)
        commit('SET_DISP_RIGHT_DR',false)
    },
    clearURL:()=>{
        let newURL =   window.location.origin
        // console.log("New URL::",newURL)
        window.history.pushState("object or string", "Page Title", newURL);
    }
};


export default {
    state,
    getters,
    mutations,
    actions
};