<template>
  <v-bottom-sheet
    permanent
    v-model="getDispRightDr"
    location="right"
    align-tabs="center"
    height="310"
  >
    <!-- <v-toolbar color="#fff" class="elevation-0" height="40">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn small icon @click.stop="closeRightPanel()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar> -->

    <v-card class="text-left" flat>
      <!-- <p class="text-right pr-1"> -->

      <v-btn size="x-small" style="position: absolute; text-align: center; right: 1px;" icon @click.stop="closeRightPanel()">
        <v-icon >mdi-close</v-icon>
      </v-btn>
      <!-- </p> -->
      <v-card-text class="text-center text-h6 px-3 py-0">
        <div class="px-6 pt-2" style="color: #8B0015;line-height: 0.85; font-weight: 600 !important; font-size: 17px;">
          {{ getClassification["Building"] }}
        </div>
        <div class="" style="color: #8B0015;font-size: 16px;font-weight: 500;">{{getBldgAddress}}</div>
        <div
          v-if="getClassification.RoomID" style="color: #8B0015; font-size: 15px;font-weight: 500;margin-bottom: -4px;margin-top: -6px;"
          class="font-italic"
        >
          Room {{ getClassification["RoomID"] }} - Floor
          {{ getClassification["FLID"] }}
        </div>
      </v-card-text>

      <v-tabs
        v-model="activeTab__"
        bg-color=""
        fixed-tabs
        density="compact"
        color="#AB0520"
      >
        <v-tab
          value="tab1"
          class="text-subtitle-1 font-weight-bold"
          size="small"
          slider-color="#AB0520"
          >Classroom Info</v-tab>
        <v-tab
          value="tab2"
          class="text-subtitle-1 font-weight-bold"
          size="small"
          slider-color="#AB0520"
          >Photos</v-tab>
      </v-tabs>

      <v-window v-model="activeTab__">
        <v-window-item value="tab1">
          <v-tooltip text="Share Room">
            <template v-slot:activator="{ props }">
              <v-btn
                class="copy-btn"
                density="compact"
                icon
                @click.stop="copyClipboard();snackbar = true;"
                style="right: 20px; position: absolute"
              >
                <v-icon density="compact" size="small">mdi-share</v-icon>
              </v-btn>
            </template>
          </v-tooltip>

          <v-snackbar v-model="snackbar">
            {{ text }}

            <template v-slot:actions>
              <v-btn color="pink" variant="text" @click="snackbar = false">
                Close
              </v-btn>
            </template>
          </v-snackbar>

          <v-card-title class="titleSize pb-0 mb-0 pt-0">Classroom Features</v-card-title>
          <v-card-text class="pb-1">
            <div>
              <span class="pl-2 font-weight-bold">Classification: </span>
              <span>{{ getClassification["Classification"] }}</span>
            </div>
            <div>
              <span class="pl-2 font-weight-bold">Configuration: </span>
              <span>{{ getClassification["Configuration"] }}</span>
            </div>
            <div>
              <span class="pl-2 font-weight-bold">Layout Type: </span
              ><span>{{ getClassification["SpaceType"] }}</span>
            </div>
            <div>
              <span class="pl-2 font-weight-bold">Occupancy: </span
              ><span>{{ getClassification["Capacity"] }}</span>
            </div>
            <div>
              <span class="pl-2 font-weight-bold">Stage: </span
              ><span>{{ getClassification["Stage"] }}</span>
            </div>
            <div>
              <span class="pl-2 font-weight-bold">Writing Surface: </span>
              <span>{{ getClassification["WritingSurface"] }}</span>
            </div>          
          </v-card-text>
          <v-divider class="mx-0 mb-0" dark="true"></v-divider>

          <v-card-title class="titleSize py-0 my-0">Panopto Capability</v-card-title>
          <v-card-text class="pt-0 mt-0 pb-1">
            <li v-if="getClassification['PanaptoInstalled'] == 'Yes'">
              Panopto - Software Installed
            </li>
            <li v-else>Panopto - Software not installed</li>

            <li v-if="getClassification['PanaptoElmo'] == 'Yes'">
              Panopto - Elmo connected
            </li>
            <li v-else>Panopto - Elmo not connected</li>

            <li v-if="getClassification['PanaptoRoomDesktopCamera'] == 'D'">
              Panopto - Desktop Camera Available
            </li>
            <li v-else-if="getClassification['PanaptoRoomDesktopCamera'] == 'R'">
              Panopto - Room Camera Available
            </li>
            <!-- <li v-else-if="getClassification['PanaptoRoomDesktopCamera'] == 'N'">
              Panopto - Desktop camera
            </li> -->
            <li v-else>Panopto - No Camera Available</li>
          </v-card-text>

          <v-divider class="mx-1 mb-0"></v-divider>

          <v-card-title class="titleSize py-0 my-0">Equipment</v-card-title>
          <v-card-text class="pt-0 mt-0">
            <li v-for="(item, i) in getClassification['equiplist']" :key="i">
              <template v-if="item > 1">{{ item }}</template>
              {{ i }}
            </li>
          </v-card-text> 
        </v-window-item>
        <v-window-item value="tab2">
                      <!-- show-arrows -->
        <div v-if="getClassification['imgs'] && getClassification['imgs'].length>0">
          <v-carousel
          
            :hide-delimiters="false"
            v-if="getClassification['imgs']"
            hide-delimiter-background
            class="px-2 pt-2"
            height="50%"
            v-model="currImg"
          >
          <template v-slot:prev="{ props }">
          <!-- <v-btn density="comfortable" variant="text" color="white" icon="mdi-chevron-left" @click="props.onClick"></v-btn> -->
          <v-btn
            density="compact"
            size="large"
            icon="mdi-chevron-left"
            @click="props.onClick"
          ></v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn
            density="compact"
            size="large"
            icon="mdi-chevron-right"
            @click="props.onClick"
          ></v-btn>
          </template>
            <v-carousel-item
              v-for="(item, i) in getClassification['imgs']"
              :key="i"
              :src="item"
            >
              <div class="text-center">
                <v-tooltip text="Expand image">
                  <template v-slot:activator="{ props }">
                    <v-btn
                      density="compact"
                      icon
                      @click.stop="dialog = true"
                      style="right: 20px; top: 2px; position: absolute"
                      v-bind="props"
                    >
                      <v-icon density="compact" size="small">mdi-arrow-expand</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>

                <v-dialog v-model="dialog" width="auto">
                  <v-card>
                    <v-card-text class="pa-0">
                      <img
                        :src="getClassification['imgs'][currImg]"
                        style='width:100%'
                      />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="primary" block @click="dialog = false">Close</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-carousel-item>
          </v-carousel>
        </div>
      <div v-else class="d-flex justify-center" >
        <img src="https://maps.arizona.edu/images/No_image_available.png" width="200"  />
      </div>
        </v-window-item>
      </v-window>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import SrchClassrooms from "./SrchClassrooms.vue";
import SrchEquipment from "./SrchEquipment.vue";
import Clipboard from "clipboard";

// const store = useStore();

export default {
  data: () => ({
    loading: false,
    snackbar: false,
    text: `Room share URL Copied to the clipboard`,
    selection: 1,
    currImg: null,
    dialog: false,
    activeTab__: "tab1",

  }),

  watch: {
    getClassification: function (newVal) {
      this.currImg = 0;
      console.log("GET CLASSIFICATION UPDATE:: ", newVal);
    },
    getSelectIntRm: function (newVal) {
      this.currImg = 0;
      console.log("GET SELECT IN Room:: ", newVal);
    },
  },
  computed: {
    ...mapState(["classification"]),
    ...mapGetters([
      "getClassification",
      "getBtnToggleTable",
      "getDispRightDr",
      "getCurrImgIdx",
      "getSelectIntRm",
      "getSelectIntBldg",
      "getSelectedEquip",      "getSelectedClassification",
      "getSelectedCapacity","getBldgAddress"
    ]),
  },

  components: {
    SrchClassrooms,
    SrchEquipment,
  },

  methods: {
    closeRightPanel() {
      this.SET_DISP_RIGHT_DR(false);
      this.SET_BTN_TOGGLE_TBL(false);
      this.SET_LEFT_DR(true);
      // this.SET_MOB_TOP_TAB('table') // show mob "Table" tab 
      // this.SET_INT_BLDG(null)
      // this.SET_INT_RM(null)
      /*if(this.getSelectedEquip) {
        this.selectEquipAction(this.getSelectedEquip)
      } else this.processSelectBuilding(this.getSelectIntBldg) // reset room and add bulding feature
      */

      let splitURL = this.$route.path.split("/");
      if (splitURL.length > 1) {
        this.$router.push({ path: "/" });
        // this.$router.push({ path: "/ctsmap" });

        // this.clearBuildings();
        // this.removeLayers();       
        
        this.SET_MOB_TOP_TAB('table') // show mob "Table" tab
      } 
      if (this.getSelectedEquip) {
          setTimeout(() => {          
          // console.log("RESET Select EQUITPMEN")
          this.selectEquipAction(this.getSelectedEquip);        
          }, 500);
        } else if (this.getSelectedClassification){
          console.log("this.getSelectedClassification::",this.getSelectedClassification)
          this.populateClassificationAction(this.getSelectedClassification)
        } else if (this.getSelectedCapacity){
          console.log("this.getSelectedCapacity::",this.getSelectedCapacity)
          this.populateCapacityAction(this.getSelectedCapacity)
        } 
        else if(this.getPreserveBldg) {
            this.processSelectBuilding(this.getSelectIntBldg);      
        } // reset room and add bulding feature  
        else this.clearAllBuildings()

      // this.buildingSelectHeader()
    },

    copyClipboard() {
      const clipboard = new Clipboard(".copy-btn", {
        // console.log("Copy CLip board...")
        text: () => window.location.href,
      });

      clipboard.on("success", () => {
        console.log("Text copied to clipboard", window.location.href, this.$route);
        clipboard.destroy(); // Cleanup
      });

      clipboard.on("error", () => {
        console.error("Unable to copy text to clipboard");
        clipboard.destroy(); // Cleanup
      });

      // Trigger the copy
      clipboard.onClick({ currentTarget: document.querySelector(".copy-btn") });
    },

    ...mapMutations([
      "SET_ACTIVE_TAB",
      "SET_DISP_RIGHT_DR",
      "SET_BTN_TOGGLE_TBL",
      "SET_LEFT_DR", "SET_MOB_TOP_TAB","SET_INT_BLDG","SET_INT_RM"
    ]),
    ...mapActions([
      "processSelectBuilding",
      "selectEquipAction",
      "clearBuildings","getPreserveBldg",
      "removeLayers","populateClassificationAction","populateCapacityAction","clearAllBuildings"
    ]),
  },
};
</script>
<style scoped>
>>>.v-slide-group{
  overflow: unset;
  --v-tabs-height: 25px !important;
}

>>>.v-window__container {
    height: 100%;
    overflow: auto;
}
.titleSize {
  font-size: 1.00rem;
    font-weight: 600;
}
>>>.v-responsive__sizer{
  padding-bottom: 73% !important;
}
</style>