<template>
  <v-container class="ma-0 pa-0" fluid="true">
    <v-row no-gutters>
      <v-col cols="12">
        <v-btn
          small
          rounded="false"
          v-if="!getBtnToggleTable"
          @click.stop="bottomSheetVisib(false)"
          style="position: revert-layer; margin-top: -45px"
        >
          <v-icon size="xx-large">mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          rounded="false"
          v-else
          @click.stop="bottomSheetVisib(true)"
          style="position: revert-layer; margin-top: -45px"
        >
          <v-icon size="xx-large">mdi-chevron-up</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-card
          height="500"
          v-if="!getBtnToggleTable"
          variant="flat"
          color="transparent"
        >
          <v-bottom-sheet
            v-model="sheet"
            contained
            flat
            persistent
            no-click-animation
          >
            <v-card class="text-center" scrim="false" variant="flat">
              <v-card-text>
                <div id="tableDiv" ref="tableDiv"></div>
                <v-btn variant="text" @click="sheet = !sheet"> close </v-btn>
                <br />
                <br />
                <div>This is a bottom sheet that is using the inset prop</div>
              </v-card-text>
            </v-card>
          </v-bottom-sheet>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

import { onMounted, ref } from "vue";
import { isProxy, toRaw } from "vue";
const tableDiv = ref(null);
// onMounted(()=>{
//   console.log("Bottom sheet mounted", document.getElementById , tableDiv.value)
// })

export default {
  data: () => ({
    sheet: true,
    tiles: [
      { img: "keep.png", title: "Keep" },
      { img: "inbox.png", title: "Inbox" },
      { img: "hangouts.png", title: "Hangouts" },
      { img: "messenger.png", title: "Messenger" },
      { img: "google.png", title: "Google+" },
    ],
  }),

  mounted() {
    console.log(
      "Bottom sheet mounted",
      document.getElementById,
      tableDiv.value
    );
    console.log("Bottom Sheet Visib:: ", this.$refs["tableDiv"]);
  },

  computed: {
    btnToggle: {
      get() {
        // return this.selectIntBldg;
        return this.getBtnToggleTable;
      },
      set(value) {
        // console.log("Value::", value);
        this.SET_BTN_TOGGLE_TBL(!value);
        // this.bottomSheetVisib(value)
        // this.populateEquipments(value)
        // this.SET_DISP_RIGHT_DR(false)
      },
    },

    // ...mapState({
    //   stateActiveTab: "activeTab",
    //   stateTestString: "testString",
    // }),
    ...mapGetters(["getBtnToggleTable", "getFeatureTable"]),
  },

  methods: {
    bottomSheetVisib(val) {
      // if(val){
      //   this.bottomSheetVisib(!val)
      // } else {
      //   this.bottomSheetVisib(val)
      // }
      // this.bottomSheetVisib(!val)
      // console.log("Bottom Sheet Visib:: ", val, this.$refs['tableDiv'])
      this.SET_BTN_TOGGLE_TBL(!val);
      if (val) {
        const fTble = toRaw(this.getFeatureTable);

        // fTble.container = document.getElementById("tableDiv")
        fTble.container = this.$refs["tableDiv"];
        fTble.refresh();
        this.SET_FEATURE_TBL_CONTAINER(this.$refs["tableDiv"]);
        console.log("FTABLE", fTble);
      }
    },

    ...mapMutations(["SET_BTN_TOGGLE_TBL", "SET_FEATURE_TBL_CONTAINER"]),
    ...mapActions(["populateEquipments"]),
  },
};
</script>

<style >
.v-overlay--active .v-overlay__scrim {
  display: none;
}

.v-overlay__content .v-bottom-sheet__content {
  margin-top: 0px !important;
}
</style>
<style scoped>
>>> .v-card-text {
  /* height: 500px; */
}
>>> .v-bottom-sheet > .v-bottom-sheet__content.v-overlay__content {
  box-shadow: none;
  height: 600px;
}
>>> .v-overlay {
  height: 600px;
}
</style>