<template>
        <v-app-bar color="#0C234B">
        
        <!--  Hide the A logo below          
          <a
        href="https://www.arizona.edu"
        title="The University of Arizona homepage"
        class="remove-external-link-icon"
        target="_blank"
        rel="noopener noreferrer">
        <img alt="The University of Arizona Wordmark Line Logo White" width="45"  src="@/assets/ALogo.8a94ef7.png" class="ml-6 mt-2">
      </a>       -->

        <!-- <v-app-bar-title class="text-left text-h5" >             -->
        <v-app-bar-title class="text-left text-h5" style="font-family: 'Proxima Nova Bold';" >
            
            Centrally Scheduled Classrooms </v-app-bar-title>

        <v-spacer></v-spacer>

        <!-- <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-heart</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
            

        <template v-slot:append>
          <v-btn icon="mdi-dots-vertical"></v-btn>
        </template> -->

      </v-app-bar>      

  </template>       

<style scoped>
.arizona-line-logo {
  top: 22px;
  position: absolute;
  height: 21px;
}
</style>