<template>
    <v-bottom-sheet persistent="true" permanent align-tabs="center" v-model="displayLeftDrawer" no-click-animation :retain-focus="false" class="sheet-ele"  content-class="elevation-10">
    <!-- Search by   -->
    <v-card color="pa-0 ma-0" flat>
    <v-row class="mt-5"></v-row>
    <v-tabs
      v-model="activeTab"
      bg-color=""      
      fixed-tabs
      text
      color="#AB0520"
    >
      <v-tab value="tab1" class="text-subtitle-1 font-weight-bold" size="small" slider-color="#AB0520" :stacked="true" width="30" :ripple="false" :variant="flat">Building & Room</v-tab>
      <v-tab value="tab3" class="text-subtitle-1 font-weight-bold" size="small" slider-color="#AB0520" :stacked="true" width="30" :ripple="false">Room Details</v-tab>
      <v-tab value="tab2" class="text-subtitle-1 font-weight-bold" size="small" slider-color="#AB0520" :stacked="true" width="30" :ripple="false" :variant="plain">Equipment</v-tab>
    </v-tabs>

    
      <v-window v-model="activeTab">
        <v-window-item value="tab1"><SrchClassrooms></SrchClassrooms></v-window-item>
        <v-window-item value="tab2"><SrchEquipment></SrchEquipment></v-window-item>
        <v-window-item value="tab3"><SrchClassification></SrchClassification></v-window-item>
      </v-window>
      <!-- <BottomSheet></BottomSheet> -->
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import SrchClassrooms from "./SrchClassrooms.vue";
import SrchEquipment from "./SrchEquipment.vue";
import SrchClassification from "./SrchClassification.vue";

export default {
  data: () => ({
    activeTab: 'tab1',
    displayLeftDrawer: true
  }),

  watch: {
    activeTab: function(val){
      console.log("Active tab value Mobile:", val)
      this.SET_ACTIVE_TAB(val);
      if(val == 'tab3'){
       
        // clears tab1
        if(this.getSelectedEquip) this.selectEquipAction(null)
        // this.populateCapacityAction(null)
        if(this.getSelectIntBldg) {
           this.SET_INT_BLDG(null)
           this.SET_INT_RM(null)
           this.clearAllBuildings()
          }

        this.SET_LYR_EFFECT('')
        // clear the selected rooms
        // console.log("Clear selected building...!")
        // this.clearBuildings()
        // this.clearAllBuildings()

        
      } 
      else if(val == 'tab2'){
        this.SET_LYR_EFFECT('')
        // clear the selected rooms
        // console.log("Clear selected building...!")
        // this.clearBuildings()
        this.clearAllBuildings()
        
        if(this.getSelectedClassification) {
          this.SET_SELECT_CLASSIFICATION(null)
        }
        if(this.getSelectedCapacity) this.SET_SELECT_CAPACITY(null)

      } 
      else if(val == 'tab1') {
        this.SET_LYR_EFFECT('1')
        
        
        // clears equip tab ... // clear the selected equipment
        if(this.getSelectedEquip) {
          console.log("Clear selected equipment...!")
          this.selectEquipAction(null) 
        }

        // this.SET_SELECT_CAPACITY(null)
        // this.SET_SELECT_CLASSIFICATION(null)
     
        // this.populateCapacityAction(null)
        if(this.getSelectIntBldg) {
           this.SET_INT_BLDG(null)
           this.SET_INT_RM(null)
          }
        if(this.getSelectedClassification) {
          this.SET_SELECT_CLASSIFICATION(null)
        }
        if(this.getSelectedCapacity) {
          this.SET_SELECT_CAPACITY(null)
        }
        this.clearAllBuildings()
      } 
    }
  },
  computed: {

    ...mapState({
      stateActiveTab: "activeTab",
      
    }),
    ...mapGetters(["getActiveTab","getLeftDr","getSelectIntBldg","getSelectedCapacity","getSelectedClassification","getSelectedEquip","getBtmShtRmsHt"]),
   
  },

  components: {
    SrchClassrooms, 
    SrchEquipment,     
    SrchClassification
  },

  methods: {
    ...mapMutations(["SET_ACTIVE_TAB","SET_LEFT_DR","SET_INT_BLDG","SET_INT_RM","SET_LYR_EFFECT","SET_SELECT_CAPACITY","SET_SELECT_CLASSIFICATION"]),
    ...mapActions(['clearBuildings','selectEquipAction','clearAllBuildings']),
  },
};
</script>
<style >
v-window-item--active{
  height: 40vh;
}
</style>
<style scoped>
>>>.v-list-item{
  padding: 0 !important;
}
>>>.v-window-item v-window-item--active{
  height: 40vh;
}
>>>.v-btn:hover > .v-btn__overlay {
  background-color: transparent;
}
</style>
<style lang="scss">
  @use 'src/styles/settings';
  

  .sheet-ele {
    $bottom-sheet-elevation: settings.$bottom-sheet-elevation;
  }
</style>