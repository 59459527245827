<template>
  <v-app>
    <v-app-bar
      class="elevation-0"
      color="#AB0520"
      style="height: 64px; padding: 0px 16px"
    >
          <!-- v-if="!getIsMobile" -->

      <a
        href="https://www.arizona.edu"
        title="The University of Arizona homepage"
        class="arizona-logo remove-external-link-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt="The University of Arizona Wordmark Line Logo White"
          src="https://cdn.uadigital.arizona.edu/logos/v1.0.0/ua_wordmark_line_logo_white_rgb.min.svg"
          class="arizona-line-logo"
        />
      </a>
    </v-app-bar>

    <PageWrapper></PageWrapper>
  </v-app>
</template>

<script>
import PageWrapper from "./components/PageWrapper.vue";
import { useHead } from "@vueuse/head";
import { mapState, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    PageWrapper,
  },
  mounted() {
    this.updateHead();
  },
  methods: {
    updateHead() {
      useHead({
        meta: [
          {
            name: "viewport",
            // content: 'width=device-width, initial-scale=1, maximum-scale=1'
            content:
              "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no",
          },
        ],
      });
    },
  },
  computed: {
    ...mapState([]),
    ...mapGetters(["getIsMobile"]),
  },
};
</script>
<style>
html {
  /* overflow: auto !important; */
  overscroll-behavior: none;
  overflow: hidden !important;
}
html,
body {
  width: 100;
  height: 100%;
  padding: 0;
  margin: 0;
}

* {
  touch-action: manipulation;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100;
  height: 100%;
  padding: 0;
  margin: 0;
}
.arizona-logo {
  display: inline-block;
  height: 20px;
  margin: 25px 0 20px;
  max-width: 80%;
  float: left;
  width: 276px;
  padding: 0 0 0 0.6rem;
}

/* 52.5px */
</style>