<template>
  <v-card    
    class="elevation-0"
    tile
    flat
    
  >
  <!-- :width="getIsMobile ? '270' : '340'"     -->
    <v-card-text class="pt-0 pb-0">
      
        <v-card flat >
          <!-- :width="getIsMobile ? '224' : '250'" -->
           <v-row>
        <v-col cols="12" class="ma-1">
          <!--<v-select
            variant="solo"
            v-model="values"
            :items="items"
            label="Default"
            density="compact"
          ></v-select>
           v-model="selectedBuilding"
                v-on:click="selectedBuilding = null"
                v-on:focus="selectedBuilding = null"
                @keyup.enter="directEnter"-->
        </v-col>
        </v-row> 
          <v-row align="center">
            
            <v-col cols="12" class="pt-5 pb-0  ml-1 pr-12" >
             <!-- -- {{ getSelectIntRm }} -- {{ getSelectIntBldg }} -->
              <!-- <v-select
                v-model="selectedEquip"        
                variant="solo"               
                :items="getEquipList"
                item-title="Building"
                item-value="SpaceNumText"
                label="Select Equipment"
                hide-no-data
                color="grey darken-1"
                clearable
              >  
              </v-select> -->

              <v-select
                v-model="selectedEquip"        
                variant="solo"               
                :items="getEquipList"
                item-title="Building"
                item-value="SpaceNumText"
                label="Select Equipment"
                hide-no-data
                density="compact"
                color="grey darken-1"
                clearable
              >  
              </v-select>
            </v-col>
          </v-row>
          
          
        </v-card>
        
        <p v-if="getSelectedEquip" class="pt-3 font-italic"> Table currently displaying all centrally scheduled classrooms with {{ getSelectedEquip }}(s), and map showing all buildings they are in. </p>
        <p v-else class="pt-3 font-italic"> Table currently displaying all centrally scheduled classrooms, and map showing all buildings where they are located. </p>
        

        <v-divider
          v-if="!getIsMobile"
          dark
          class="dividerClass2"
          vertical
        ></v-divider>
      
    </v-card-text>

  </v-card>
</template>   

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    // activeTab: "tab1",
  }),

  watch: {},
  computed: {
    ...mapState({
      stateActiveTab: "activeTab",
      stateTestString: "testString",
    }),
    ...mapGetters({
      getIsMobile: "getIsMobile",
      getEquipList: "getEquipList",
      getSelectedEquip: "getSelectedEquip"
    }),

    selectedEquip: {
        get() {
          // return this.selectIntBldg;
          return this.getSelectedEquip;
        },
        set(value) {          
          // console.log("Value::", value);
          this.selectEquipAction(value)
          this.logActivity(["Equipment Search", value])
        },
  },
},

    methods: {
      ...mapMutations(["SET_SELECTED_EQUIP","SET_DISP_RIGHT_DR"]),
      ...mapActions(['populateEquipments','selectEquipAction','logActivity'])
    }
 
};
</script>

<style scoped>
</style>