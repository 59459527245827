<template>
  <v-main id="appContainer">
    <!-- v-main Important to display full map view -->
    <div class="map-view" id="viewDiv"></div>

    <!-- <v-card height="500" outlined color="transparent">  -->
    <!--set height to 0 when hide-->

    <v-container class="ma-0 pa-0" :fluid="true">
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            small
            rounded="false"
            v-if="!getBtnToggleTable"
            @click.stop="bottomSheetVisib(false)"
            style="position: revert-layer; margin-top: -45px"
          >
          <v-icon size="xx-large" color="#ab0520">mdi-chevron-down</v-icon>
          </v-btn>
          <v-btn
            rounded="false"
            v-else
            @click.stop="bottomSheetVisib(true)"
            style="
              position: revert-layer;
              margin-top: -45px;
              background: #ab0520;
            "
          >
            <v-icon size="xx-large" color="white">mdi-chevron-up</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <v-card
            v-show="!getBtnToggleTable"
            height="40vh"
            variant="flat"
            color="transparent"
          >
            <!-- <div class="text-center" v-if="tblLoading">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>

        <div id="tableDiv" ref="tableDiv" v-else></div>       -->
            <div id="tableDiv" ref="tableDiv"></div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <!--  
      <BottomSheet>

      </BottomSheet>-->
    <!-- </v-card> -->

    <!-- <div id="tableContainer" class="container"> -->
    <!-- <v-row no-gutters>        
        <v-col cols="12" style="height: 100%" id="colDiv"> -->
    <!-- <div id="tableDiv"></div> -->
    <!-- </v-col>
      </v-row> -->
    <!-- </div> -->
  </v-main>
</template>
<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { ref, toRaw } from "vue";

import ArcGISMap from "@arcgis/core/WebMap";
import MapView from "@arcgis/core/views/MapView";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer.js";
import FeatureTable from "@arcgis/core/widgets/FeatureTable.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import Graphic from "@arcgis/core/Graphic.js";
import BottomSheet from "./BottomSheet.vue";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

// import { useRouter } from 'vue-router'
// const router = useRouter();

// import uniqueValues from "@arcgis/core/smartMapping/statistics/uniqueValues.js";

// import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";

// const close = () => {
//   myBottomSheet.value.close();
// }

export default {
  sheet1: true,
  sheet: false,
  name: "WebMap",
  map: null,
  view: null,
  spaceNumText: "",
  featureLayerBldgs: "",
  featureLayerClassRmInfo: "",
  roomGLayer: "",
  roomLabels: "",
  buildingGLayer: "",
  levelGLayer: "",
  featureTable: "",
  roomkey: "",
  tblLoading: false,
  FLID: "",
  btntoggle: "0",
  shareParm: window.location.pathname.split("/")[1],
  // shareParm: "",  // Production,
  // tableContainer: document.getElementById("tableContainer"),
  // appContainer: document.getElementById("appContainer"),
  props: {
    msg: String,
  },

  watch: {
    getRoomNumbers: function (newVal) {
      let newRms = toRaw(newVal);
      let splitURL = this.$route.path.split("/");

      console.log("getRoomNumbers::", newRms, " splitURL::", this.shareParm);
      if (splitURL.length == 3 && newRms.length > 0) {
        // means there is room and building in URL //change it to 3 for production without subdirectory url
        // console.log("Building and Rooms", splitURL[2], " --- ", splitURL[3]);
        // this.processSelectBuilding(splitURL[1]).then(()=>{
        let roomID = splitURL[2];
        // this.SET_INT_RM(value);
        console.log("Room NNUMBERS::", roomID, " --- ", this.getRoomNumbers);
        let Room = this.getRoomNumbers.find((rms) => rms.ID == roomID);

        Room = toRaw(Room);
        console.log("Room::", Room);

        let value = Room.RoomKey;
        // console.log("Floor key::", Room.FL, " OBJ ID:: ", Room);

        this.searchHighlightInteriorRooms([
          this.getSelectIntBldg,
          value,
          Room.FL,
        ]);

        // this.SET_INT_RM_OID(Room.OBJECTID);

        this.SET_DISP_RIGHT_DR(true);
        this.populateClassification([value, Room.ID]);
        //popuplate data from room equipment

        setTimeout(() => {
          this.populateRoomEquipment(value);
          this.populateRoomImages([this.getSelectIntBldg, Room.ID, value]);
        }, 1000);
        // this.populateClassification([value,Room.ID])
        // this.selectRoomFeatTable(Room)

        this.SET_BTN_TOGGLE_TBL(true);
        this.SET_LEFT_DR(false);
        // })
      }
    },
  },

  components: { BottomSheet },
  mounted() {
    this.map = new ArcGISMap({
      portalItem: {
        id: this.msg,
      },
    });

    this.view = new MapView({
      map: this.map,
      // container: this.$el,
      container: "viewDiv",
      popup: {
        autoOpenEnabled: false,
      }, // Disable popups
    });
    this.view.ui.components = [];
    let _this = this;
    this.view.on("click", function (event) {
      console.log(event);
      // _this = this
      event.stopPropagation(); // overwrite default click-for-popup behavior
      let screenPoint = event.screenPoint;

      _this.view.hitTest(event).then(function (response) {
        // only get the graphics returned from myLayer
        const graphicHits = response.results?.filter(
          (hitResult) =>
            hitResult.type === "graphic" &&
            hitResult.graphic.layer === _this.featureLayerBldgs
        );
        if (graphicHits?.length > 0 && _this.getActiveTab == "tab1") {
          _this.SET_PRESERVE_BLDG(true);
          // do something with the myLayer features returned from hittest
          graphicHits.forEach((graphicHit) => {
            console.log(
              "Attributes:: ",
              graphicHit.graphic,
              " --- ",
              _this.getBldgsList
            );
            let currBldg =
              graphicHit.graphic.attributes["Buildings.SpaceNumLetter"];
            let bldgFound = _this.getBldgsList.find(
              (e) => e["SpaceNumText"] == currBldg
            );
            console.log(currBldg, " -- ", bldgFound);
            if (bldgFound) _this.processSelectBuilding(currBldg);
            else _this.SET_INT_BLDG(null);
          });
        }
      });

      // })
    }); //view on click

    this.view.when(() => {
      // console.log("view ready..!", this.view);
      this.SET_VIEW(this.view);
      this.SET_MAP(this.map);
      this.SET_TABLE_CONT(document.getElementById("tableContainer"));

      /*
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        //deviceType = "mobile";
        this.SET_IS_MOB(true);
      }
      // this.loadRooms(21);
      // load Rooms
      */
      // Add Graphics Layer
      this.roomGLayer = new GraphicsLayer({ id: "roomGLayer" });
      this.roomLabels = new GraphicsLayer({ id: "roomLabels", minScale: 1128 });
      this.levelGLayer = new GraphicsLayer({ id: "levelGLayer" });
      this.buildingGLayer = new GraphicsLayer({ id: "buildingGLayer" });

      this.featureLayerBldgs = new FeatureLayer({
        url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/3",
        id: "bldgsFL",
        // outFields: ["Buildings.NAME", "Buildings.SpaceNum", "Buildings.SpaceNumLetter","Buildings.Address","SD_Buildings.abbrev"],
        // outFields: ["NAME", "SpaceNum", "SpaceNumLetter","Address","abbrev"],
        outFields: ["*"],
        opacity: 0.6,
        effect: [
          {
            scale: 36978595,
            value: "drop-shadow(1px, 1px, 2px)",
          },
        ],
      });
      // map.addMany([roomGLayer,buildingGLayer,levelGLayer,featureLayerBldgs])
      this.featureLayerClassRmInfo = new FeatureLayer({
        url: "https://services.maps.arizona.edu/pdc/rest/services/CTSMap/MapServer/1",
        id: "classroomTable",
        // title: "US Colleges and Universities"
      });

      this.map.addMany([
        // this.featureLayerBldgs,   // hide this when buildings is selected
        this.roomGLayer,
        this.roomLabels,
        this.buildingGLayer,
        this.levelGLayer,
      ]);

      let splitURL = this.$route.path.split("/"); // change splitURL[2] with splitURL[1] for production
      let splitURLLen = splitURL.length;
      // console.log("splitURL::", splitURL, " length:: ", splitURLLen);
      this.featureLayerBldgs.when(() => {
        if (
          splitURL.length > 1 &&
          splitURL[splitURLLen - 2].length > 0 &&
          splitURL[splitURLLen - 2] !== "ctsmap"
        ) {
          // means there is JUST building in URL
          this.SET_PRESERVE_BLDG(true);
          // console.log("Just Building::",splitURL[splitURLLen-2])
          this.processSelectBuilding(splitURL[splitURLLen - 2]);
          this.logActivity([
            "From ShareURL",
            "Building :" +
              splitURL[splitURLLen - 2] +
              ", Room: " +
              splitURL[splitURLLen - 1],
          ]);
        }
        // console.log("Buildings loading...")
        let query = this.featureLayerBldgs.createQuery();
        let buildings = [];
        let _this = this;
        this.featureLayerBldgs.queryFeatures(query).then(function (response) {
          if (response.features.length > 0) {
            // console.log("Buildings Query features:: ", response.features)
            let bldg = {};
            let bluildQuery = "";
            let feature = response.features;
            // console.log("attributes:: ",response.features)
            feature.forEach((ft) => {
              // console.log(ft.attributes.SpaceNumLetter)
              bldg = {};
              if ( ft.attributes["Buildings.SpaceNumText"] == "103" || ft.attributes["Buildings.SpaceNumText"] == "75" ) {
                // bldg.Building = ft.attributes["Buildings.Name"].slice(0, 24);
                bldg.Building = ft.attributes["Buildings.Name"]
                // console.log("This is space Num test...",bldg.Building)
              } else bldg.Building = ft.attributes["Buildings.Name"]; //.slice(0,13)

              bldg.SpaceNumText = ft.attributes["Buildings.SpaceNumLetter"];
              bldg.Address = ft.attributes["Buildings.Address"];
              bldg.abbrv = ft.attributes["SD_Buildings.abbrev"];
              if (bluildQuery == "") {
                bluildQuery += "'" + ft.attributes["Buildings.SpaceNumLetter"] + "'";
              } else {
                bluildQuery += ",'" + ft.attributes["Buildings.SpaceNumLetter"] + "'";
              }

              bldg.value = ft.attributes["Buildings.SpaceNumLetter"];
              bldg.title =
                ft.attributes["Buildings.SpaceNumLetter"] +
                "-" +
                ft.attributes["Buildings.Name"] +
                "-" +
                ft.attributes["SD_Buildings.abbrev"]; // Add more here if want to search more
              buildings.push(bldg);
            });

            buildings.sort((a, b) => a["Building"] < b["Building"] ? -1 : a["Building"] > b["Building"] ? 1 : 0 ).values;
            // console.log("Buildings::", buildings)
            _this.SET_BLDGS_LIST(buildings);
          }
        });
      });

      this.featureLayerClassRmInfo.when(() => {
        // console.log("When !uery");
        let buildings = [];
        let query = this.featureLayerClassRmInfo.createQuery();
        // query.where = "OBJECTID = "+features[0]
        // query.where = "SpaceNumText = '" + this.spaceNumText + "'";
        query.outFields = ["Building", "SpaceNumText"];
        query.returnDistinctValues = true;
        let bluildQuery = "";
        this.featureLayerClassRmInfo
          .queryFeatures(query)
          .then(function (response) {
            if (response.features.length > 0) {
              let bldg = {};
              let feature = response.features;
              // console.log("attributes:: ",response.features)
              feature.forEach((ft) => {
                bldg = {};
                if (ft.attributes.SpaceNumText == "103") {
                  bldg.Building = ft.attributes.Building;
                  // bldg.Building = ft.attributes.Building.slice(0, 24);
                  // console.log("This is space Num test...",bldg.Building)
                } else bldg.Building = ft.attributes.Building; //.slice(0,13)

                bldg.SpaceNumText = ft.attributes.SpaceNumText;
                bldg.Address = ft.attributes["Buildings.Address"];
                if (bluildQuery == "") {
                  bluildQuery += "'" + ft.attributes.SpaceNumText + "'";
                } else {
                  bluildQuery += ",'" + ft.attributes.SpaceNumText + "'";
                }

                bldg.value = ft.attributes.SpaceNumText;
                bldg.title =
                  ft.attributes.SpaceNumText + "-" + ft.attributes.Building; // Add more here if want to search more
                buildings.push(bldg);
              });
            }
            console.log("Classrooom Buildings:: ", buildings);
            //  buildings.sort((a, b) => a["title"] < b["title"] ? -1 : a["title"] > b["title"] ? 1 : 0).values
            buildings.sort((a, b) =>
              a["Building"] < b["Building"]
                ? -1
                : a["Building"] > b["Building"]
                ? 1
                : 0
            ).values;
            _this.SET_BLDGS_LIST(buildings);
            _this.SET_BLDGS_QRY(bluildQuery);
            _this.featureLayerBldgs.definitionExpression =
              "Buildings.SpaceNumLetter IN (" + bluildQuery + ")";
            console.log("FL Building::", _this.featureLayerBldgs);
            _this.map.addMany([_this.featureLayerBldgs]);
            if (!_this.getIsMobile) _this.resetFeatureTable();
            //  console.log("Building::", "SpaceNumLetter IN "+bluildQuery)
            //  console.log("Test ::","SpaceNumLetter IN '"+buildings+"'")
            // Set Header Col After buildings are loaded
            _this.buildingSelectHeader();
          });
      });

      this.loadBuildingsInfo();

      // Create the FeatureTable from the provided FeatureLayer
      this.featureTable = new FeatureTable({
        view: this.view,
        layer: this.featureLayerClassRmInfo,
        // multiSortEnabled: false,
        visibleElements: {
          selectionColumn: false,
          header: false,
          columnMenus: false,
        },
        autoRefreshEnabled: true,
        tableTemplate: {
          // Autocast to TableTemplate
          columnTemplates: [
            // Takes an array of FieldColumnTemplate and GroupColumnTemplate
            {
              type: "field",
              fieldName: "Building",
              label: "Building",
              direction: "asc",
            },
            {
              // Autocast to FieldColumnTemplate.
              type: "field",
              fieldName: "RoomID",
              label: "Room",
            },
            {
              type: "field",
              fieldName: "Classification",
              label: "Classification",
            },
            {
              type: "field",
              fieldName: "Capacity",
              label: "Capacity",
            },
            {
              type: "field",
              fieldName: "Configuration",
              label: "Configuration",
            },
          ],
        },
        // container: document.getElementById("tableDiv"),
        // container: this.$refs.tableDiv
      });
      /*
      this.featureTable.on("pointer-move", function(e){
        console.info("pointer-move",e);
      })  
*/

      this.featureTable.on("refresh", function (event) {
        // This function will be called whenever the feature table is refreshed

        // Capture the state here
        // captureState();
        console.log(">>>> Refresh Feture Table... ");
      });

      this.featureTable.when((e) => {
        // this.featureTable.on("pointer-move:mouseover", function(){
        //       console.info("hover");
        //       //do your tooltip here
        //     });
      });

      this.featureTable.container = this.$refs["tableDiv"];
      const table = ref(null);
      console.log("references:: ", this.$parent, table.value);
      this.SET_FEATURE_TBL(this.featureTable);
      this.SET_APP_CONTAINTER(document.getElementById("appContainer"));

      let features = [];
      // Listen for changes in the collection of highlighted features
      // Listen for the table's selection-change event
      this.featureTable.on("selection-change", (changes) => {
        this.featureTable.highlightIds.removeAll();
        // if the feature is unselected then remove the objectId
        // of the removed feature from the features array
        // featureTable.highlightIds.removeAll();
        changes.removed.forEach((item) => {
          // console.log("item::", item)
          const data = features.find((data) => {
            return data === item.objectId;
          });
          if (data) {
            features.splice(features.indexOf(data), 1);
          }
        });
        var classroomInfo = {};
        // If the selection is added, push all added selections to array
        changes.added.forEach((item) => {
          console.log("??? item:", item);
          this.SET_DISP_RIGHT_DR(true);

          this.spaceNumText = item.feature.attributes.SpaceNumText;
          this.roomkey = item.feature.attributes.Join_Key;
          let RoomID = item.feature.attributes.RoomID;
          this.FLID = item.feature.attributes.FLID;

          //popuplate data from room equipment

          this.populateRoomEquipment(this.roomkey);
          this.populateRoomImages([this.spaceNumText, RoomID, this.roomkey]);
          let objId = item.feature.attributes.OBJECTID;
          classroomInfo["Building"] = item.feature.attributes.Building;
          classroomInfo["RoomID"] = RoomID;
          classroomInfo["FLID"] = item.feature.attributes.FLID;
          classroomInfo["Classification"] =
            item.feature.attributes.Classification;
          classroomInfo["Configuration"] =
            item.feature.attributes.Configuration;
          classroomInfo["SpaceType"] = item.feature.attributes.SpaceType;
          classroomInfo["Capacity"] = item.feature.attributes.Capacity;
          classroomInfo["Stage"] = item.feature.attributes.Stage;
          classroomInfo["WritingSurface"] =
            item.feature.attributes.WritingSurface;
          classroomInfo["PanaptoInstalled"] =
            item.feature.attributes.PanaptoInstalled;
          classroomInfo["PanaptoElmo"] =
            item.feature.attributes.PanaptoInstalled;
          classroomInfo["PanaptoRoomDesktopCamera"] =
            item.feature.attributes.PanaptoRoomDesktopCamera;
          classroomInfo["StudentWheelchairAccess"] =
            item.feature.attributes.StudentWheelchairAccess;
          classroomInfo["TeachingWheelchairAccess"] =
            item.feature.attributes.TeachingWheelchairAccess;
          this.SET_CLASSIFICATION(classroomInfo);
          this.SET_INT_BLDG(this.spaceNumText); //For left menu populate building and room
          // this.refreshFeatureLayer(this.spaceNumText)
          this.SET_INT_RM(RoomID);
          // else
          if (!this.getPreserveBldg) this.SET_PRESERVE_BLDG(false);
          console.log(
            "this.shareParm::",
            this.shareParm,
            "this.getSelectIntBldg::",
            this.getSelectIntBldg
          );
          this.logActivity([
            "Room Select - Table",
            "Building :" + this.spaceNumText + ", Room: " + RoomID,
          ]);          
          // let newURL = window.location.origin + "/ctsmap" + "/" + this.spaceNumText + "/" + RoomID;
          let newURL = window.location.origin + "/" + this.spaceNumText + "/" + RoomID;
          window.history.pushState("object or string", "Page Title", newURL);
          features.push(objId);
        });
        console.log(
          "classroomInfo::",
          classroomInfo,
          " --- features.length:: ",
          features.length
        );
        this.SET_BTN_TOGGLE_TBL(true);
        this.SET_LEFT_DR(false);

        let i = 0;
        // this.highlighFeatures(features[0]);

        if (JSON.stringify(classroomInfo) !== "{}") {
          if (features.length == 1) {
            console.log(
              "highlight first::",
              features.length,
              " feature::",
              features
            );
            this.highlighFeatures(features[0]);
          } else {
            while (features.length > 1) {
              console.log(
                "highlight second::",
                features.length,
                " feature::",
                features
              );
              this.featureTable.highlightIds.remove(features[i]);
              features.shift(); // Keep only one feature
              i++;
              // console.log("features::>", features + "," + this.spaceNumText);
              this.highlighFeatures(features[0]);
            }
          }
        }

        // console.log("after while",features)
      });

      // Loading below causing a bug where table returns empty upon clear from right panel in some cases
      // reactiveUtils.when(() => this.featureTable.state === 'loading', () => {console.log(">>>>Loading in progress");this.tblLoading=true});
      // reactiveUtils.when(() => this.featureTable.state === 'loaded', () => {console.log(">>>>Loaded");this.tblLoading=false});

      this.populateDistEquipment();
 

      console.log(
        "Feature Header::",
        document.querySelector("td[first-column]")
      );
    });
  },

  computed: {
    btnToggle: {
      get() {
        // return this.selectIntBldg;
        return this.getBtnToggleTable;
      },
      set(value) {
        console.log("Value::", value);
        this.SET_BTN_TOGGLE_TBL(!value);
      },
    },
    ...mapState(["View"]),
    ...mapGetters({
      getView: "getView",
      getBtnToggleTable: "getBtnToggleTable",
      getTableCont: "getTableCont",
      getSelectIntBldg: "getSelectIntBldg",
      getAppContainer: "getAppContainer",
      getBldgsList: "getBldgsList",
      getBtnToggleTable: "getBtnToggleTable",
      getFeatureTable: "getFeatureTable",
      getRoomNumbers: "getRoomNumbers",
      getActiveTab: "getActiveTab",
      getPreserveBldg: "getPreserveBldg",
      getIsMobile: "getIsMobile",
    }),
  },

  methods: {
    bottomSheetVisib(val) {
      this.SET_BTN_TOGGLE_TBL(!val);
      if (val) {
        console.log(
          "Bottom Sheet Visib:: ",
          val,
          this.$refs["tableDiv"],
          this.featureTable
        );
        this.featureTable.container = this.$refs["tableDiv"];
        this.featureTable.refresh();
      }
    },

    highlighFeatures() {
      let roomGLayer = this.map.layers.find((e) => e.id == "roomGLayer");
      let roomLabels = this.map.layers.find((e) => e.id == "roomLabels");
      let buildingGLayer = this.map.layers.find(
        (e) => e.id == "buildingGLayer"
      );
      // console.log("buildingGLayer:: ",buildingGLayer)
      // this.map.layers.find(e=>{ console.log(e.id);});
      //   console.log(e.title,"--",e.id);
      // })

      // Select Building on the map
      let query = this.featureLayerBldgs.createQuery();
      // query.where = "OBJECTID = "+features[0]
      query.where = "SpaceNumText = '" + this.spaceNumText + "'";
      // query.outFields = "*"
      let _this = this;
      this.featureLayerBldgs.queryFeatures(query).then(function (response) {
        if (response.features.length > 0) {
          // building = response.features
          let address = response.features[0].attributes["Buildings.Address"];
          _this.SET_BLDG_ADDRESS(address);
          console.log(">>> response::", response.features, address);
          // Add Buildings on the map
          let graphicA = new Graphic({
            // graphic with line geometry
            geometry: response.features[0].geometry, // set geometry here
            symbol: {
              type: "simple-fill",
              // outline: { width: 3, color: [0, 0, 0] },
              outline: { width: 2, color: [171, 5, 32] },
              color: [255, 255, 255, 0.15],
            }, // set symbol here
          });
          _this.hideBuildingsLyr();
          buildingGLayer.removeAll();
          roomGLayer.removeAll();
          roomLabels.removeAll();
          buildingGLayer.graphics.add(graphicA);
          var opts = {
            duration: 1000, // Duration of animation will be 5 seconds
          };
          // console.log("Target:: ",response.features[0].geometry, "-- ", _this.view)
          _this.view.goTo(
            {
              target: response.features[0].geometry,
              // zoom: 19
            },
            opts
          );
        }
      });

      // this.SET_BLDGFL(this.featureLayerBldgs);
      this.searchHighlightInteriorRooms([
        this.spaceNumText,
        this.roomkey,
        this.FLID,
      ]);
    },

    resetFeatureTable() {
      const grid = this.featureTable.container.querySelector("vaadin-grid");
      // console.log("Grid::",grid," grid::", this.featureTable.grid?.findColumn("RoomID"))
      console.log("Grid::", this.featureTable.grid.columns.items);
      console.log("Grid::", this.featureTable.grid.columns.items[1].width);
      if (this.featureTable.grid.columns.items.length > 0) {
        this.featureTable.grid.columns.items[1].width = 75; // Adjust Rooms Width
        this.featureTable.grid.columns.items[2].width = 100; // Adjust Rooms Width
        this.featureTable.grid.columns.items[3].width = 75; // Adjust Rooms Width
      }

      grid?.addEventListener("cell-activate", (e) => {
        const selected = e.detail.model.selected;
        console.log("selected..");
        if (e.detail.model.item) {
          const feature = e.detail.model.item.feature;
          selected
            ? this.featureTable.deselectRows(feature)
            : this.featureTable.selectRows(feature);
        }
      });
    },

    ...mapMutations([
      "SET_IS_MOB",
      "SET_VIEW",
      "SET_APP_CONTAINTER",
      "SET_CLASSIFICATION",
      ,
      "SET_BTN_TOGGLE_TBL",
      "SET_TABLE_CONT",
      "SET_DISP_RIGHT_DR",
      "SET_BLDGS_LIST",
      "SET_MAP",
      "SET_FEATURE_TBL",
      "SET_INT_BLDG",
      "SET_INT_RM",
      "SET_BTN_TOGGLE_TBL",
      "SET_FEATURE_TBL_CONTAINER",
      "SET_BTN_TOGGLE_TBL",
      "SET_LEFT_DR",
      "SET_LYR_EFFECT",
      "SET_PRESERVE_BLDG",
      "SET_BLDGS_QRY",
      "SET_BLDG_ADDRESS",
    ]),
    ...mapActions([
      "loadRooms",
      "populateRoomEquipment",
      "populateRoomImages",
      "loadBuildingsInfo",
      "searchHighlightInteriorRooms",
      "populateDistEquipment",
      "hideBuildingsLyr",
      "refreshFeatureLayer",
      "processSelectBuilding",
      "populateClassification",
      "buildingSelectHeader",
      "logActivity",
    ]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://js.arcgis.com/4.25/esri/themes/light/main.css");

/*
.map-view {
  height: 70%;
  width: 100%;
}
.container {
  height: 30%;
  width: 100%;
}
*/
#appContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#viewDiv {
  flex: 1;
  width: 100%;
  height: 62%;
}
.maintbDiv {
  display: flex;
  flex: 1;
  width: 100%;
}
.container {
  display: flex;
  flex: 1;
  width: 100%;
}
/** Remove black focus boundary around map */
.esri-view {
  /* .esri-view-surface--inset-outline:focus::after { */
  --esri-view-outline-color: none !important;
  --esri-view-outline: none !important;
}

/** Hide map attribution */
/* .esri-attribution {
  display: none;
} */

>>> .esri-field-column__header-content {
  font-weight: bold !important;
  text-transform: uppercase;
}

>>> .esri-feature-table vaadin-grid {
  text-align: left;
  padding-left: 10px !important;
}

>>> .esri-widget * {
  text-align: left;
  padding-left: 10px !important;
}

/* Custom styles for the select column header */
/* .esri-feature-table .esri-feature-table__header th:first-child::before {
      content: "Your Custom Text";
      display: block;
      padding: 8px;
      text-align: center;
    } */

>>> .esri-feature-table .esri-feature-table__body tbody tr:hover {
  background-color: #f0f0f0;
}

>>> .esri-grid {
  --lumo-row-background-hover: #fa6e6c !important;
}
</style>
<style >
.esri-feature-table .esri-feature-table__header th:first-child::before {
  content: "Your" !important;
  display: block;
  padding: 8px;
  text-align: center;
}

.esri-feature-table .esri-feature-table__body tbody tr:hover {
  background-color: #f0f0f0;
}

/* .esri-grid{
      --lumo-row-background-hover:#FA6E6C
    }    
     */

vaadin-grid-cell-content {
  white-space: normal !important;
}
</style>