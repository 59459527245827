const state = { 
    topTabs: "table",
    bottomSheetRoomsHeight: null
};
const getters = { 
    getTopTabs: (state) => state.topTabs,
    getBtmShtRmsHt: (state) => state.bottomSheetRoomsHeight,
};
const mutations = { 
    SET_TOP_TABS: (state, payload) => {
        state.topTabs = payload;
        console.log(state.isMobile)
    },
    SET_BS_RMS_HT: (state, payload) => {
        // console.log(">>> Set Height::",payload)
        state.bottomSheetRoomsHeight = payload;
    }
};
const actions = {

 };

/** 
const state = { };
const getters = { };
const mutations = { };
const actions = { };
 */

export default {
    state,
    getters,
    mutations,
    actions
};