
import Vuex from "vuex";
import Vue from "vue";
// import simulation from "./modules/simulation";
// import routing from "./modules/routing";
// Vue.use(Vuex);

/*
export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  // modules: { simulation, routing },
});

//   };

//   export default store;

*/
import { createStore } from 'vuex';
import mapFuncs from "./modules/mapFuncs";
import mobFuncs from "./modules/mobFuncs";
import search from "./modules/search";

export default createStore({    
  modules: { search, mapFuncs, mobFuncs }
});