import { isProxy, toRaw } from 'vue';
const state = { 
    isMobile: null,
    View: null,
    Map: null,
    lyrEffect: [
        {
          scale: 36978595,
          value: "drop-shadow(1px, 1px, 2px)"
        }]
};
const getters = { 
    getIsMobile: (state) => state.isMobile,
    getView: (state) => state.View,
    getMap: (state) => state.Map,
    getLyrEffect: (state) => state.lyrEffect,
};
const mutations = {
    
    SET_IS_MOB: (state, tabName) => {
        state.isMobile = tabName;
        console.log(state.isMobile)
    },

    SET_VIEW: (state, payload) => {
        state.View = payload;        
    },

    SET_MAP: (state, payload) => {
        state.Map = payload;        
    },

    SET_LYR_EFFECT: (state, payload) => {
        const view = state.View
        let map = toRaw(state.Map)  
        // console.log(map.allLayers)
        var allLayers = map.allLayers.items;
        const bldgsFL = allLayers.find(l => (l.id == "bldgsFL"))
        bldgsFL.effect = ''
        // console.log("bldFL::",bldgsFL)
        if(payload == '') {
            bldgsFL.effect = ''
            // console.log("SET_LYR_EFFECT .... In the IF...")
        } else {
            bldgsFL.effect = [{
                                scale: 36978595,
                                value: "drop-shadow(1px, 1px, 2px)"
                                }]
            // console.log("SET_LYR_EFFECT ... In the Else...")
        }
        state.lyrEffect = payload;        
    },
};
const actions = {

    loadBuildingsInfo: ({ commit }, payload) => { 
       const strBldgList = ['0119.00','0550.00','0002.00','0002.01','0151.01','0043.00','0088.00','0024.00','0101.00','0023.00','0041.00','0072.00','0156.00','0095.00','0025.00','0073.00','0028.00','0128.00','0003.00','0069.00','0104.00','0030.01','0020.00','0137.00','0420.00','0036.00','0092.00','0077.00','0113.00','0093.00','0070.00','0176.00','0103.00','0011.00','0107.00','0089.00','0108.00','0078.00','0096.00','0094.00','0012.00','0067.00','0004.00','0203.00','0207.00','0081.00','0457.00','0076.00','0075.00','0033.00','0038.00','0027.00','0032.00','0071.00','0065.00','0017.00','0054.00']

    } ,

    buildingSelectHeader: () => { 
        if(document.getElementsByClassName("vaadin-grid-select-all-checkbox")[0]){
            var firstHeader = document.getElementsByClassName("vaadin-grid-select-all-checkbox")[0].parentElement
            firstHeader.innerText = "Select"
        }
        
        
        // console.log("Feature Header::",firstHeader)  

        // if(document.getElementsByClassName("esri-grid__grid")){
        //     var gridElem = document.getElementsByClassName("esri-grid__grid")[0]
        //     console.log("Feature Header::",gridElem)  
        // }
        
    },

    logActivity: (_, payload) => {
        let type = payload[0];
        let comment = payload[1];
        console.log("Log Activity::", type," -- ", comment)
        
        fetch("https://api.pdc.arizona.edu/ctsmap/comment", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          method: "POST",
          body: JSON.stringify({ type: type, comment: comment }),
        })
          .then()
          .catch((e) => {
            console.error("error");
            console.error(e);
          });        
      },


 };

/** 
const state = { };
const getters = { };
const mutations = { };
const actions = { };
 */

export default {
    state,
    getters,
    mutations,
    actions
};