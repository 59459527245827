<template>
  <v-card class="elevation-0" tile flat>
    <!-- :width="getIsMobile ? '270' : '340'"     -->
    <v-card-text class="pt-0 pb-0">
      <v-card flat>
        <!-- :width="getIsMobile ? '224' : '250'" -->
        <v-row>
          <v-col cols="12" class="ma-1"> </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="pt-5 pb-0 ml-1 pr-12">
            <v-select
              v-on:focus="selectedBuilding = null"
              v-model="selectedBuilding"
              clearable
              persistent-clear
              variant="solo"
              density="compact"
              :items="getBldgsList"
              label="Select Building"
              click:clear="testClear()"
              hide-no-data
              color="grey darken-1"
            >
              <template v-slot:selection="{ props, item }">
                <v-list-item
                  v-bind="props"
                  :title="item?.raw?.Building"
                  item-props
                >
                  <template v-slot:prepend>
                    <v-avatar size="x-small" color="grey" rounded="0">
                      {{ item?.raw?.SpaceNumText }}
                    </v-avatar>
                  </template>
                </v-list-item>
              </template>

              <template v-slot:item="{ props, item }">
                <v-list-item
                  item-props
                  v-bind="props"
                  :title="item?.raw?.Building"
                >
                  <template v-slot:prepend>
                    <v-avatar color="grey" rounded="0">
                      {{ item?.raw?.SpaceNumText }}
                    </v-avatar>
                  </template>
                  <!--
                                Un comment below for the second line-->
                  <template v-slot:subtitle>
                    <div>{{ item?.raw?.abbrv }}</div>
                  </template>
                </v-list-item>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" class="pt-5 pb-0 ml-1 pr-12">
            <v-select
              v-if="getRoomNumbers.length > 0"
              density="compact"
              v-model="selectedRooms"
              variant="solo"
              :items="getRoomNumbers"
              item-title="ID"
              item-value="RoomKey"
              filter="customFilter"
              label="Select Room"
              hide-no-data
              color="grey darken-1"
              clearable
            >
            </v-select>

            <v-select
              v-else
              disabled
              density="compact"
              v-model="selectedRooms"
              variant="solo"
              :items="getRoomNumbers"
              item-title="ID"
              item-value="RoomKey"
              filter="customFilter"
              label="Select Room"
              hide-no-data
              color="grey darken-1"
              clearable
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>
      <p v-if="this.bldgName" class="pt-3 pl-1 pr-8 font-italic">
        Table currently displaying centrally scheduled classrooms in
        {{ this.bldgName.Building }}
      </p>

      <p v-else class="pt-3 pl-1 pr-8 font-italic">
        Table currently displaying all centrally scheduled classrooms, and map
        showing all buildings where they are located.
      </p>

      <v-divider
        v-if="!getIsMobile"
        dark
        class="dividerClass2"
        vertical
      ></v-divider>
    </v-card-text>
    <!-- <v-card-actions class="pt-0">
      <v-spacer></v-spacer>
      <v-btn
        small
        color="primary"
        ref="clearBtn"
        @click="
          selectedBuilding = null;
          selectedRoom = null;
          search.clearSearch();
          search.clearURL();
        "
      >
        clear
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions> -->
  </v-card>
</template>   

<script>
import { toRaw } from "vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    select: "",
    bldgName: "",
    shareParm: window.location.pathname.split("/")[1], // Beta Share Params
    // shareParm: "",  // Production,
    add: { id: 1 },
    // activeTab: "tab1",
  }),

  watch: {},
  computed: {
    ...mapState({
      stateSelectIntBldg: "selectIntBldg",
      stateSelectIntRm: "selectIntRm",
    }),
    ...mapGetters({
      getIsMobile: "getIsMobile",
      getRoomNumbers: "getRoomNumbers",
      getBldgsList: "getBldgsList",
      getSelectIntBldg: "getSelectIntBldg",
      getSelectIntRm: "getSelectIntRm",
    }),

    selectedBuilding: {
      get() {
        // return this.selectIntBldg;
        return this.getSelectIntBldg;
      },
      set(value) {
        let newURL = undefined;

        this.SET_PRESERVE_BLDG(true);
        if (this.shareParm == value) {
          this.shareParm = "";
        }
        if (value) {
          let bldgName = this.getBldgsList.find((e) => {
            return e.SpaceNumText == value;
          });
          this.bldgName = toRaw(bldgName);
          // console.log("Building Value::", value," --- ",toRaw(this.bldgName) , " shareParm::", this.shareParm," -- ",window.location.pathname);

          // newURL = window.location.origin + "/" + this.shareParm + "/" + value;
          newURL = window.location.origin + "/" + value;
          // console.log("Process building:: ",newURL, "window.location.origin:",window.location.origin, "this.shareParm:",this.shareParm," value:",value)
          this.processSelectBuilding(value);
        } else {
          // newURL = window.location.origin
          // if(this.shareParm) newURL = window.location.origin +'/'+ this.shareParm
          // else
          this.bldgName = null;
          this.SET_PRESERVE_BLDG(false);
          newURL = window.location.origin + "/" + this.shareParm;
          // console.log("Building Clear Value::", newURL);
          this.clearAllBuildings(); //works when clear button clicked
        }

        window.history.pushState("object or string", "Page Title", newURL);
      },
    },
    selectedRooms: {
      get() {
        // return this.stateSelectIntRm;
        return this.getSelectIntRm;
      },
      set(value) {
        let newURL = undefined;
        if (value) {
          // console.log("Room Value::", value);
          this.SET_INT_RM(value);
          let Room = this.getRoomNumbers.find((rms) => rms.RoomKey == value);
          Room = toRaw(Room);
          // console.log("Room::", Room)
          // console.log("Floor key::", Room.FL, " OBJ ID:: ", Room);
          this.searchHighlightInteriorRooms([
            this.getSelectIntBldg,
            value,
            Room.FL,
          ]);
          this.SET_INT_RM_OID(Room.OBJECTID);
          this.SET_DISP_RIGHT_DR(true);
          this.populateClassification([value, Room.ID]);
          //popuplate data from room equipment

          newURL = window.location.origin + "/" + this.getSelectIntBldg + "/" + Room.ID;

          setTimeout(() => {
            this.populateRoomEquipment(value);
            this.populateRoomImages([this.getSelectIntBldg, Room.ID, value]);
            // console.log(">>>> Room Search", "Building :"+this.getSelectIntBldg+", Room: "+Room.ID)
            this.logActivity([
              "Room Search",
              "Building :" + this.getSelectIntBldg + ", Room: " + Room.ID,
            ]);
          }, 1000);
          // this.populateClassification([value,Room.ID])
          // this.selectRoomFeatTable(Room)

          this.SET_BTN_TOGGLE_TBL(true);
          this.SET_LEFT_DR(false);

          // this.SET_BTN_TOGGLE_TBL(false)
        } else {
          newURL =
            window.location.origin + "/" + this.shareParm + "/" + Room.ID;
          // Remove drawing, floor plans
          this.removeRoomGraphics();
        }
        window.history.pushState("object or string", "Page Title", newURL);
      },
    },
  },

  methods: {
    testClear() {
      console.log("Custom filter... ");
    },
    // customFilter(item, queryText, itemText) {
    //   console.log("Custom filter... ");
    // },
    // clearGBuildings(){
    //   console.log("this.clearBuildings")
    //   this.selectedBuilding = null
    //   this.SET_INT_BLDG(null)
    // },
    ...mapMutations([
      "SET_INT_BLDG",
      "SET_INT_RM",
      "SET_DISP_RIGHT_DR",
      "SET_INT_RM_OID",
      "SET_BTN_TOGGLE_TBL",
      "SET_LEFT_DR",
      "SET_PRESERVE_BLDG",
    ]),
    ...mapActions([
      "refreshFeatureLayer",
      "searchHighlightInteriorRooms",
      "hideBuildingsLyr",
      "populateRoomEquipment",
      "populateRoomImages",
      "highlightBuilding",
      "selectRoomFeatTable",
      "processSelectBuilding",
      "clearAllBuildings",
      "removeRoomGraphics",
      "populateClassification",
      "logActivity",
    ]),
  },
};
</script>

<style scoped>
</style>