<template>
  <!--
  <v-app-bar color="#AB0520" elevation="0"  density="compact" >
    <a
      href="https://www.arizona.edu"
      title="The University of Arizona homepage"
      class="remove-external-link-icon"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt="The University of Arizona Wordmark Line Logo White"
        width="35"
        
        src="@/assets/ALogo.8a94ef7.png" 

        class="mx-2 mt-2"
      />
    </a>
    <v-app-bar-title class="text-left font-weight-bold">
      Centrally Scheduled Classrooms
    </v-app-bar-title>
  </v-app-bar>
  -->

  <v-app-bar density="compact" :elevation="1">
    <!-- <v-tabs v-model="activeTab_" bg-color="" fixed-tabs density="compact" color="#AB0520" :hide-slider="true" style="width: -webkit-fill-available;">
      <v-tab value="table"
        class=" text-h6 font-weight-bold text-h6"
        size="small"        
        >Table</v-tab>
      <v-tab
        value="map"
        class=" text-h6 font-weight-bold"
        size="small"        
        >Map</v-tab>
    </v-tabs> -->
    <v-row>
      <v-col cols="12" class="py-2">
      <v-btn-toggle
            v-model="activeTab_"
            rounded="0"
            color="#ffecef"
            group
            style="width:-webkit-fill-available"            
          >
            <v-btn value="table" style="width: 50%" class=" text-h6 font-weight-bold">
              Table
            </v-btn>

            <v-btn value="map" style="width: 50%" class=" text-h6 font-weight-bold">
              Map
            </v-btn>

          </v-btn-toggle>
          </v-col>
          </v-row>

    <!-- <v-window v-model="activeTab_">
      <v-window-item value="table"></v-window-item>
      <v-window-item value="map"></v-window-item>
    </v-window> -->
  </v-app-bar>
</template>    

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    text: 'center',
    // activeTab_: 'table',
  }),

  watch: {
    activeTab_: function(val){
      // console.log("Active tab value :", val)
      
      if(val == "map"){
        this.SET_BTN_TOGGLE_TBL(true)
      } else {
        this.SET_BTN_TOGGLE_TBL(false)
      }

    }
  },
  computed: {

    ...mapState({
      // stateactiveTab_: "activeTab_",
      
    }),
    ...mapGetters(["getactiveTab","getLeftDr","getSelectIntBldg","getMobTopTab"]),
    
    activeTab_: {
      get() {
        return this.getMobTopTab;
      },
      set(value) {        
        // console.log("tob bar tab value::", value)
        this.SET_MOB_TOP_TAB(value);
        // console.log(this.getactiveTab_);
      }
    },
  },

  methods: {
    ...mapMutations(["SET_MOB_TOP_TAB","SET_BTN_TOGGLE_TBL"])
  }
}
</script>


<style scoped>
.arizona-line-logo {
  top: 22px;
  position: absolute;
  height: 21px;
}

>>>.v-toolbar__content > .v-toolbar-title{
  margin: 0 !important;
}

>>>.v-btn__content {
  color: black;
}

>>>.v-btn:hover > .v-btn__overlay { 
    opacity: 0.2; 
}

</style>
