<template>
  <v-navigation-drawer permanent align-tabs="center" width="350" v-model="displayLeftDrawer">
    <!-- Search by   -->
    <v-row class="mt-8"></v-row>
    <v-tabs
      v-model="activeTab"
      bg-color=""      
      fixed-tabs
      density="default"
      color="#AB0520"
    >
      <v-tab
        value="tab1"
        class="text-subtitle-1 font-weight-bold"
        size="small"
        slider-color="#AB0520" :stacked="true" width="50" :ripple="false"
        >Building & Room</v-tab>
        <v-tab
        value="tab3"
        class="text-subtitle-1 font-weight-bold"
        size="small"
        slider-color="#AB0520" :stacked="true" width="50" :ripple="false"
        >Room Details</v-tab>
      <v-tab
        value="tab2"
        class="text-subtitle-1 font-weight-bold"
        size="small"
        slider-color="#AB0520" width="50" :ripple="false"
        >Equipment</v-tab>
    </v-tabs>

    
      <v-window v-model="activeTab">
        <v-window-item value="tab1"><SrchClassrooms></SrchClassrooms></v-window-item>
        <v-window-item value="tab2"><SrchEquipment></SrchEquipment></v-window-item>
        <v-window-item value="tab3"><SrchClassification></SrchClassification></v-window-item>
      </v-window>
      <!-- <BottomSheet></BottomSheet> -->
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import SrchClassrooms from "./SrchClassrooms.vue";
import SrchEquipment from "./SrchEquipment.vue";
import SrchClassification from "./SrchClassification.vue";
import BottomSheet from './BottomSheet.vue';
export default {
  data: () => ({
    activeTab: 'tab1',
  }),

  watch: {
    activeTab: function(val){
      // console.log("Active tab value :", val)
      this.SET_ACTIVE_TAB(val);
      if(val == 'tab3'){
       
        // clears tab1
        if(this.getSelectedEquip) this.selectEquipAction(null)
        // this.populateCapacityAction(null)
        if(this.getSelectIntBldg) {
           this.SET_INT_BLDG(null)
           this.SET_INT_RM(null)
           this.clearAllBuildings()
          }

        this.SET_LYR_EFFECT('')
        // clear the selected rooms
        

        
      } 
      else if(val == 'tab2'){
        this.SET_LYR_EFFECT('')
        // clear the selected rooms
        // console.log("Clear selected building...!")
        // this.clearBuildings()
        this.clearAllBuildings()
        
        if(this.getSelectedClassification) {
          this.SET_SELECT_CLASSIFICATION(null)
        }
        if(this.getSelectedCapacity) this.SET_SELECT_CAPACITY(null)

      } 
      else if(val == 'tab1') {
        this.SET_LYR_EFFECT('1')
        
        
        // clears equip tab ... // clear the selected equipment
        if(this.getSelectedEquip) {
          console.log("Clear selected equipment...!")
          this.selectEquipAction(null) 
        }

        // this.SET_SELECT_CAPACITY(null)
        // this.SET_SELECT_CLASSIFICATION(null)
     
        // this.populateCapacityAction(null)
        if(this.getSelectIntBldg) {
           this.SET_INT_BLDG(null)
           this.SET_INT_RM(null)
          }
        if(this.getSelectedClassification) {
          this.SET_SELECT_CLASSIFICATION(null)
        }
        if(this.getSelectedCapacity) {
          this.SET_SELECT_CAPACITY(null)
        }
        this.clearAllBuildings()
      } 
    }

  },
  computed: {

    ...mapState({
      stateActiveTab: "activeTab",
      
    }),
    ...mapGetters(["getActiveTab","getLeftDr","getSelectIntBldg","getSelectedEquip","getSelectedCapacity","getSelectedClassification"]),
          
    displayLeftDrawer: {
      get() {
        return this.getLeftDr;
      },
      set(value) {        
        this.SET_LEFT_DR(value);
        // console.log(this.getActiveTab);
      }
    },

  },

  components: {
    SrchClassrooms, 
    SrchEquipment, 
    SrchClassification,
    BottomSheet
  },

  methods: {
    ...mapMutations(["SET_ACTIVE_TAB","SET_LEFT_DR","SET_INT_BLDG","SET_INT_RM","SET_LYR_EFFECT","SET_SELECT_CAPACITY","SET_SELECT_CLASSIFICATION"]),
    ...mapActions(['clearBuildings','selectEquipAction','clearAllBuildings','populateClassificationAction','populateCapacityAction']),
  },
};
</script>
<style scoped>
>>>.v-list-item{
  padding: 0 !important;
}
>>>.v-tab {
  white-space: normal;
}
</style>