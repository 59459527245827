import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { createRouter,createWebHistory} from 'vue-router'
// import './registerServiceWorker'

// Vuetify
import 'vuetify/styles'
// import '@/styles/main.scss'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labsComponents from 'vuetify/labs/components'
// import { createMetaManager, defaultConfig, resolveOption, useMeta } from 'vue-meta'
import './assets/css/style.css'

import { createHead } from '@vueuse/head'
const head = createHead()

import { loadFonts } from './plugins/webfontloader'
import './registerServiceWorker'
loadFonts()

const vuetify = createVuetify({
  components,
  directives,
  labsComponents,
  styles: {
    configFile: 'src/styles/settings.scss',
  },
  mobileBreakpoint: 'xs',
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 800,
      xl: 1280,
    },
})

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes:[], // short for `routes: routes`
})
/*
const decisionMaker5000000 = resolveOption((prevValue, context) => {
  const { uid = 0 } = context.vm || {}
  if (!prevValue || prevValue < uid) {
    return uid
  }
})

const metaManager = createMetaManager({
  ...defaultConfig,
  esi: {
    group: true,
    namespaced: true,
    attributes: ['src', 'test', 'text']
  }
}, decisionMaker5000000)

const meta = useMeta({
    title: 'Some Page' ,
    og: { something: 'test' }
  },metaManager )
*/
// createApp(App).use(meta).use(store).use(vuetify).use(router).mount('#app');
createApp(App).use(head).use(store).use(vuetify).use(router).mount('#app');