<template>
  <v-card    
    class="elevation-0 checkIn"
    tile :height="getBtmShtRmsHt"
    
    flat >
    <v-card-text class="pt-0 pb-0">      
        <v-card flat >          
           <v-row>
        <v-col cols="12" class="ma-1">          
        </v-col>
        </v-row> 
          <v-row align="center" class="py-0  my-0">
            
            <v-col cols="12" class="pt-2 pb-0  ml-1 pr-12 " >
                <!-- v-model="selectedClassification"   getClassificationList      -->
              <v-select
                v-model="selectedClassification"        
                variant="solo"               
                :items="getClassificationList"
                label="Select Classification"
                hide-no-data
                color="grey darken-1"
                :disabled="selectedCapcity"
                density="compact"
                clearable
              >  
              </v-select>
            </v-col>
          </v-row>
          
          <v-row class="py-0 my-0">            
            <v-col cols="12" class=" py-0 ml-1 pr-12 text-subtitle-1 font-weight-bold" >
              <div class="d-flex justify-center">OR</div>
            </v-col>
          </v-row>


          <v-row align="center" class="py-0  my-0">            
            <v-col cols="12" class="pb-0 py-0 ml-1 pr-12" >
              <v-select
                v-model="selectedCapcity"        
                variant="solo"               
                :items="getCapacityList"
                label="Select Capacity"
                density="compact"
                :disabled="selectedClassification"
                hide-no-data
                color="grey darken-1"
                clearable
                item-title="range"
                item-value="id"
              >  
              </v-select>
            </v-col>
          </v-row>
          
          
        </v-card>
        
        <p v-if="selectedClassification" class="pt-0 font-italic"> Table currently displaying all centrally scheduled classrooms classified as {{ selectedClassification }}, and map showing all buildings they are in. </p>
        <p v-else-if="getSelectedCapacity" class=" pb-0 font-italic"> Table currently displaying all centrally scheduled classrooms with a capacity in the range of {{ range }}, and map is showing all buildings they are in. </p>
        <p v-else class="pb-0 font-italic"> Table currently displaying all centrally scheduled classrooms, and map showing all buildings where they are located. </p>
        

        <v-divider
          v-if="!getIsMobile"
          dark
          class="dividerClass2"
          vertical
        ></v-divider>
      
    </v-card-text>

  </v-card>
</template>   

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";

export default {
  data: () => ({
    range: 0
    // activeTab: "tab1",
  }),

  watch: {},
  computed: {
    ...mapState({
      stateActiveTab: "activeTab",
      stateTestString: "testString",
    }),
    ...mapGetters({
      getIsMobile: "getIsMobile",
      getEquipList: "getEquipList",
      getCapacityList: "getCapacityList",
      getClassificationList: "getClassificationList",
      getSelectedCapacity: "getSelectedCapacity",
      getSelectedClassification: "getSelectedClassification",
      getBtmShtRmsHt: "getBtmShtRmsHt"
    }),

    selectedClassification: {
        get() {
          // return this.selectIntBldg;
          return this.getSelectedClassification;
        },
        set(value) {          
          // console.log("Value::", value);
          this.populateClassificationAction(value)
          // this.logActivity(["Equipment Search", value])
        },
      },

      selectedCapcity: {
            get() {
              // return this.selectIntBldg;
              return this.getSelectedCapacity;
            },
            set(value) {          
              
              // console.log("Value::", value);
              this.populateCapacityAction(value)
              // this.logActivity(["Equipment Search", value])
              if(value){ this.range = this.getCapacityList.find(e=>value==e.id).range }
              else {
                this.range=null
              }
              
            },
      },
},

    methods: {
      ...mapMutations(["SET_SELECTED_EQUIP","SET_DISP_RIGHT_DR","SET_SELECT_CLASSIFICATION","SET_SELECT_CAPACITY"]),
      ...mapActions(['populateEquipments','selectEquipAction','logActivity','populateClassification','selectCapacity','populateClassificationAction','populateCapacityAction'])
    }
 
};
</script>

<style scoped>
</style>