<template>
  <v-navigation-drawer
    permanent
    v-model="getDispRightDr"
    location="right"
    width="350"
    align-tabs="center"
  >
    <v-toolbar color="#fff" class="elevation-0" height="40">
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>

      <v-btn size="small" icon @click.stop="closeRightPanel()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card :loading="loading" max-width="350" class="text-left" flat>
      <v-card-text class="text-center">
        <div class="text-h5 font-weight-bold" style="color: #8B0015;">
       {{ getClassification["Building"] }}
        </div>        
        <div class="text-h6" style="color: #8B0015;">{{getBldgAddress}}</div>
        <div
          v-if="getClassification.RoomID"
          class="text-h6 font-italic" style="color: #8B0015;"
        > 
          Room {{ getClassification["RoomID"] }} - Floor
          {{ getClassification["FLID"] }}
        </div>
      </v-card-text>
      <template v-slot:loader="{ isActive }">
        <v-progress-linear
          :active="isActive"
          color="deep-purple"
          height="4"
          indeterminate
          show-arrows="hover"
        ></v-progress-linear>
      </template>      
      <!-- <div v-if="getClassification['imgs'].length>0"> -->
      <div v-if="getClassification['imgs'] && getClassification['imgs'].length>0">
      <v-carousel
        :hide-delimiters="false"
        show-arrows
        v-if="getClassification['imgs']"
        hide-delimiter-background
        class="px-2 pt-2"
        height="200"
        v-model="currImg"
      >
        <template v-slot:prev="{ props }">
          <!-- <v-btn density="comfortable" variant="text" color="white" icon="mdi-chevron-left" @click="props.onClick"></v-btn> -->
          <v-btn
            density="compact"
            size="large"
            icon="mdi-chevron-left"
            @click="props.onClick"
          ></v-btn>
        </template>
        <template v-slot:next="{ props }">
          <v-btn
            density="compact"
            size="large"
            icon="mdi-chevron-right"
            @click="props.onClick"
          ></v-btn>
        </template>
        <v-carousel-item
          v-for="(item, i) in getClassification['imgs']"
          :key="i"
          :src="item"
        >
          <div class="text-center">
            <!-- <v-btn color="primary" @click="dialog = true"> Open Dialog </v-btn> -->
            <!-- <v-btn density="comfortable" color="black" @click="dialog = true" icon="mdi-arrow-expand" style="right:20px; top:2px; position:absolute;" variant='elevated tonal'><v-icon>mdi-arrow-expand</v-icon></v-btn> -->
            <!-- <v-btn density="compact" size="large" icon="mdi-arrow-expand" @click="dialog = true"  style="right:20px; top:2px; position:absolute;" variant='elevated tonal' ></v-btn> -->
            <v-tooltip text="Expand image">
              <template v-slot:activator="{ props }">              
              <v-btn
                density="compact" icon @click.stop="dialog = true"
                style="right: 20px; top: 2px; position: absolute" v-bind="props"
              >
                <v-icon density="compact" size="small">mdi-arrow-expand</v-icon>
              </v-btn>
            </template>
            </v-tooltip>

            <v-dialog v-model="dialog" width="auto">
              <v-card>
                <v-card-text>
                  <img :src="getClassification['imgs'][currImg]" width="850" />
                </v-card-text>
                <v-card-actions>
                  <v-btn color="primary" block @click="dialog = false" >Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <!-- <v-btn density="comfortable" color="#033" icon="mdi-arrow-expand" style="right:40px; top:5px; position:absolute;" variant='tonal'></v-btn> -->
        </v-carousel-item>
      </v-carousel>
      </div>
      <div v-else class="d-flex justify-center" >
        <img src="https://maps.arizona.edu/images/No_image_available.png" width="150"  />
      </div>

      <v-tooltip text="Share Room">
        <template v-slot:activator="{ props }">
          <v-btn class="copy-btn" density="compact" icon @click.stop="copyClipboard(); snackbar = true;" style="right: 20px; position: absolute" v-bind="props">
            <v-icon density="compact" size="small">mdi-share</v-icon> 
          </v-btn>
       </template>
      </v-tooltip>

      <v-snackbar v-model="snackbar">
        {{ text }}

        <template v-slot:actions>
          <v-btn color="pink" variant="text" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-card-title class="pb-0 mb-0">Classroom Features</v-card-title>
      <v-card-text>
        <div>
          <span class="pl-2 font-weight-bold">Classification: </span>
          <span>{{ getClassification["Classification"] }}</span>
        </div>
        <div>
          <span class="pl-2 font-weight-bold">Configuration: </span>
          <span>{{ getClassification["Configuration"] }}</span>
        </div>
        <div>
          <span class="pl-2 font-weight-bold">Layout Type: </span
          ><span>{{ getClassification["SpaceType"] }}</span>
        </div>
        <div>
          <span class="pl-2 font-weight-bold">Occupancy: </span
          ><span>{{ getClassification["Capacity"] }}</span>
        </div>
        <div>
          <span class="pl-2 font-weight-bold">Stage: </span
          ><span>{{ getClassification["Stage"] }}</span>
        </div>
        <div>
          <span class="pl-2 font-weight-bold">Writing Surface: </span
          ><span>{{ getClassification["WritingSurface"] }}</span>
        </div>
        <div>
            <span class="pl-2 font-weight-bold">Student Wheelchair Access: </span>
            <span>{{ getClassification["StudentWheelchairAccess"] }}</span>
            <!-- <span v-else>No</span> -->
          </div>
        <div>
          <span class="pl-2 font-weight-bold">Teaching Wheelchair Access: </span>
          <span>{{ getClassification["TeachingWheelchairAccess"] }}</span>
          <!-- <span v-else>No</span> -->
            </div>
      </v-card-text>
      <v-divider class="mx-1 mb-1" dark="true"></v-divider>

      <v-card-title class="pb-0 mb-0">Panopto Capability</v-card-title>
      <v-card-text class="pt-0 mt-0">
        <li v-if="getClassification['PanaptoInstalled'] == 'Yes'">
          Panopto - Software Installed
        </li>
        <li v-else>Panopto - Software not installed</li>

        <li v-if="getClassification['PanaptoElmo'] == 'Yes'">
          Panopto - Elmo connected
        </li>
        <li v-else>Panopto - Elmo not connected</li>

        <li v-if="getClassification['PanaptoRoomDesktopCamera'] == 'D'">
          Panopto - Desktop Camera Available
        </li>
        <li v-else-if="getClassification['PanaptoRoomDesktopCamera'] == 'R'">
          Panopto - Room Camera Available
        </li>
        <!-- <li v-else-if="getClassification['PanaptoRoomDesktopCamera'] == 'N'">
          Panopto - Desktop camera
        </li> -->
        <li v-else>Panopto - No Camera Available</li>
      </v-card-text>

      <v-divider class="mx-1 mb-1"></v-divider>

      <v-card-title>Equipment</v-card-title>
      <v-card-text class="pt-0 mt-0">
        <li v-for="(item, i) in getClassification['equiplist']" :key="i">
          <template v-if="item > 1">{{ item }}</template>
          {{ i }}
        </li>

        <!--<li v-for="(item, i) in getClassification['equiplist']" :key="i">
         <template v-if="item.split(':')[1] == 0" >item.split(':')[1]</template>item.split(':')[0]         
      </li>-->
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import { toRaw, watch } from "vue";
import { useStore } from "vuex";
import SrchClassrooms from "./SrchClassrooms.vue";
import SrchEquipment from "./SrchEquipment.vue";
import Clipboard from "clipboard";

const store = useStore();

export default {
  setup(props) {
    const store = useStore();

    watch(
      () => store.getters.getClassification,
      function () {
        console.log("value changes detected");
      }
    );

    // return {
    //     myvalue: computed(() => store.getters.getClassification)
    // }
  },
  data: () => ({
    loading: false,
    snackbar: false,
    text: `Room share URL Copied to the clipboard`,
    selection: 1,
    currImg: null,
    dialog: false,
  }),

  watch: {
    getClassification: function (newVal) {
      this.currImg = 0;
      console.log("GET CLASSIFICATION UPDATE:: ", newVal);
    },
    getSelectIntRm: function (newVal) {
      this.currImg = 0;
      console.log("GET SELECT IN Room:: ", newVal);
    },
  },
  computed: {
    ...mapState(["classification"]),
    ...mapGetters([
      "getClassification",
      "getBtnToggleTable",
      "getDispRightDr",
      "getCurrImgIdx",
      "getSelectIntRm",
      "getSelectIntBldg",
      "getSelectedEquip",
      "getFeatureTable",
      "getPreserveBldg",
      "getSelectedClassification",
      "getSelectedCapacity",
      "getBldgAddress"
    ]),
  },

  components: {
    SrchClassrooms,
    SrchEquipment,
  },

  methods: {
    closeRightPanel() {
      this.SET_DISP_RIGHT_DR(false);
      this.SET_BTN_TOGGLE_TBL(false);
      this.SET_LEFT_DR(true);

      let splitURL = this.$route.path.split("/");
      if (splitURL.length > 1) {
        this.$router.push({ path: "/" });
        // this.$router.push({ path: "/ctsmap" });        
        // this.removeLayers();       
        // this.clearBuildings();
      } 
     
      // this.SET_MOB_TOP_TAB('table')

      if (this.getSelectedEquip) {
          console.log("Inside close if...")
          setTimeout(() => {          
          // console.log("RESET Select EQUITPMEN")
          this.selectEquipAction(this.getSelectedEquip);        
          }, 500);
        } else if (this.getSelectedClassification){
          console.log("this.getSelectedClassification::",this.getSelectedClassification)
          this.populateClassificationAction(this.getSelectedClassification)
        } else if (this.getSelectedCapacity){
          console.log("this.getSelectedCapacity::",this.getSelectedCapacity)
          this.populateCapacityAction(this.getSelectedCapacity)
        } 
        else if(this.getPreserveBldg) {
            console.log("Inside close Else if Previous Building...")
            this.processSelectBuilding(this.getSelectIntBldg);      
        } // reset room and add bulding feature  
        else this.clearAllBuildings()
      
    },

    copyClipboard() {
      const clipboard = new Clipboard(".copy-btn", {
        // console.log("Copy CLip board...")
        text: () => window.location.href,
      });

      clipboard.on("success", () => {
        console.log("Text copied to clipboard", window.location.href, this.$route);
        clipboard.destroy(); // Cleanup
      });

      clipboard.on("error", () => {
        console.error("Unable to copy text to clipboard");
        clipboard.destroy(); // Cleanup
      });

      // Trigger the copy
      clipboard.onClick({ currentTarget: document.querySelector(".copy-btn") });
    },

    ...mapMutations([
      "SET_ACTIVE_TAB",
      "SET_DISP_RIGHT_DR",
      "SET_BTN_TOGGLE_TBL",
      "SET_LEFT_DR",
    ]),
    ...mapActions([
      "processSelectBuilding",
      "selectEquipAction",
      "clearBuildings",
      "removeLayers","clearAllBuildings","populateClassificationAction","populateCapacityAction"
    ]),
  },
};
</script>