<template>
<template v-if="getIsMobile">

  <WebMapMob id="appContainer"   msg="aa0ea7b1f3d94127990dbab07e2d3fc3" />
  <LeftNavDrawerMob id="bottomContainter" ></LeftNavDrawerMob>

  <TopBarMob ></TopBarMob>
  <RightNavDrawerMob></RightNavDrawerMob>
</template>

<template v-else>
  <WebMap msg="aa0ea7b1f3d94127990dbab07e2d3fc3" />
  <LeftNavDrawer></LeftNavDrawer>
  <TopBar></TopBar>
  <RightNavDrawer></RightNavDrawer>
</template>

    
    <!-- <BottomSheet></BottomSheet> -->
</template>
<script>
import WebMap from "./WebMap.vue";
import TopBar from "./TopBar.vue";

import WebMapMob from "./mobile/WebMap.vue";
import TopBarMob from "./mobile/TopBar.vue";

import LeftNavDrawer from './LeftNavDrawer.vue';
import LeftNavDrawerMob from './mobile/LeftNavDrawer.vue';

import RightNavDrawer from './RightNavDrawer.vue';
import RightNavDrawerMob from './mobile/RightNavDrawer.vue';

// import BottomSheet from './BottomSheet.vue';

import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
export default {
  name: "App",
  components: {
    WebMap,
    WebMapMob,
    TopBarMob,
    TopBar,
    LeftNavDrawer,
    LeftNavDrawerMob,
    RightNavDrawer,
    RightNavDrawerMob
  },
  mounted(){
    // this.isMobile = window.innerWidth < 768;
    // console.log(this.isMobile); // logs `false`

    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    if (/Android|iPhone|iPod|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        //deviceType = "mobile";
        this.SET_IS_MOB(true);
        console.log("MObile True")
      } 
      // else {
      //   console.log("MObile False")
      // }
  },

  computed: {
    ...mapState([]),
    ...mapGetters(["getIsMobile"]),
  },

  methods: {
    ...mapMutations(["SET_IS_MOB"]),
  }
};
</script>

<!-- <script setup>
  import { onMounted } from 'vue'
  import { useDisplay } from 'vuetify'

  const { mobile } = useDisplay({})

  // Given a viewport width of 960px
  onMounted(() => {
    console.log("Mobile View::: ",mobile.value) // false
    if(mobile.value) {
      console.log("in the if..")
      this.SET_IS_MOB(true)
    };
  }) 
</script> -->
<style scoped>
     #appContainer, #bottomContainter {
      height: 50%;
      flex: 1;
      overflow: auto;
      transition: height 0.3s ease;
    }
</style>